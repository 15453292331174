// SkeletonLoading component

import React from 'react';

const SkeletonLoading = () => {
  return (
    <div className="animate-pulse space-y-4">
      {/* Create multiple divs to represent skeleton loading */}
      <div className="h-4 bg-gray-300 rounded w-3/4"></div>
      <div className="h-4 bg-gray-300 rounded w-1/2"></div>
      <div className="h-4 bg-gray-300 rounded w-5/6"></div>
      <div className="h-4 bg-gray-300 rounded w-3/4"></div>
      <div className="h-4 bg-gray-300 rounded w-4/5"></div>
    </div>
  );
};

export default SkeletonLoading;
