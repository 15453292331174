import React, { useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getAuth, onAuthStateChanged  } from "firebase/auth";
import SkeletonLoading from '../components/SkeletonLoading'


const auth = getAuth(); // Initialize Firebase Auth

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  // Check user authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup function
  }, []);

  // Render route based on user authentication state
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === null ? (
          // Loading state, you may want to show a loading spinner here
          // <div>Loading...</div>
          <SkeletonLoading/>
          
        ) : isAuthenticated ? (
          // If authenticated, render the component
          <Component {...props} />
        ) : (
          // If not authenticated, redirect to login
          <Redirect to="/signin" />
        )
      }
    />
  );
};

export default ProtectedRoute;
