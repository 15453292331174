import React from 'react';
import Header from './Header'; // Assuming you have a Header component
import Footer from './Footer'; // Assuming you have a Footer component

const PDFViewer = ({ match }) => {
    const fileUrl = `/media/pdf/${match.params.pdfFile}`;

    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <div className="flex justify-center items-center flex-grow bg-blue-950">
                <iframe
                    src={fileUrl}
                    title="PDF Document"
                    width=""
                    height=""
                    className="w-screen h-screen  bg-blue-950 rounded-lg shadow-lg"
                ></iframe>
            </div>
        </div>
    );
};

export default PDFViewer;
