import React from 'react';
import Header from './Header';
import Hero from './Hero';
import About from './About';
// import CourseTracks from './CourseTracks';
import Accreditation from './Accreditation';
import Enumeration from './Enumeration';
import Executives from './Executives';
import Contact from './Contact';
import Footer from './Footer';
import Organogram from './Organogram';

const Main = () => {
  return (
    <>
      <Header />
      <Hero />
      <About />
      {/* <CourseTracks /> */}
      <Accreditation />
      {/* <Enumeration /> */}
      <Organogram/>
      <Executives />
      {/* <Contact /> */}
      <Footer />
    </>
  );
};

export default Main;
