// utils/dateUtils.js

export const getMonthNames = () => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const previousMonthIndex = (currentMonthIndex === 0) ? 11 : currentMonthIndex - 1;
  
    const currentMonthName = months[currentMonthIndex];
    const previousMonthName = months[previousMonthIndex];
  
    return { currentMonthName, previousMonthName };
  };
  