import React, { useState, useEffect, useRef } from 'react';
import app from '../firebase/firebase';
import { getStorage } from "firebase/storage";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

const storage = getStorage(app);

const CheckboxWithFileInput = ({ id, label, formData, setFormData }) => {
  const [fileDisabled, setFileDisabled] = useState(true);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileName, setFileName] = useState('');
  const fileInputRef = useRef(null);

  useEffect(() => {
    setFileDisabled(!(formData[id] === true || formData[id] === false));
  }, [formData, id]);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setFileDisabled(!checked);
    setFormData((prevData) => ({
      ...prevData,
      [id]: checked,
    }));
    if (!checked) {
      setFileName('');
      setUploadProgress(0);
      fileInputRef.current.value = ''; // Clear the file input
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const uniqueName = `${uuidv4()}-${file.name}`;
      setFileName(file.name);
      const storageRef = ref(storage, `uploads/${uniqueName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed',
        (snapshot) => {
          // Calculate upload progress
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error('Upload error:', error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setFormData((prevData) => ({
              ...prevData,
              [`${id}_file`]: downloadURL,
            }));
          });
        }
      );
    }
  };

  return (
    <div className="flex items-center flex-wrap mb-2">
      <input
        type="checkbox"
        id={id}
        className="mr-2"
        checked={formData[id] === true}
        onChange={handleCheckboxChange}
      />
      <label htmlFor={id} className="text-sm font-medium text-gray-700 mr-4">{label}</label>
      <input
        type="file"
        ref={fileInputRef}
        className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        onChange={handleFileChange}
        disabled={fileDisabled}
      />
      {fileName && (
        <div className="mt-2 text-sm text-gray-600">{fileName}</div>
      )}
      {uploadProgress > 0 && (
        <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
          <div
            className="bg-blue-500 h-2.5 rounded-full"
            style={{ width: `${uploadProgress}%` }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default CheckboxWithFileInput;
