import React from 'react';
import governorImage from '../img/governor.png';
import deputyGovernorImage from '../img/deputy.png';
import directorImage from '../img/director.png';

const executives = [
  {
    name: 'State Governor',
    image: governorImage,
    title: 'Governor'
  },
  {
    name: 'Deputy Governor',
    image: deputyGovernorImage,
    title: 'Deputy Governor'
  },
  {
    name: 'Director of the Agency',
    image: directorImage,
    title: 'Director'
  }
];

const Executives = () => {
  return (
    <section id="executives" className="py-20 bg-gray-100">
      <div className="container mx-auto text-center px-5 md:px-32">
        <h2 className="text-3xl font-semibold text-gray-700 mb-4">Our Executives</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {executives.map((executive, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg transform transition-transform hover:scale-105"
            >
              <img
                src={executive.image}
                alt={executive.name}
                className="h-64 object-cover mx-auto mb-4"
              />
              
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Executives;
