import React, { useState } from 'react';

const Accreditation = () => {
    const [showFullAccreditation, setShowFullAccreditation] = useState(false);
    const [showFullEnumeration, setShowFullEnumeration] = useState(false);
    const [showFullMandate, setShowFullMandate] = useState(false);

    const toggleAccreditation = () => {
        setShowFullAccreditation(!showFullAccreditation);
    };

    const toggleEnumeration = () => {
        setShowFullEnumeration(!showFullEnumeration);
    };
    const toggleMandate = () => {
        setShowFullMandate(!showFullMandate);
    };

    return (
        <div className="py-20 mx-5 md:mx-32 gap-10 grid grid-cols-1 md:grid-cols-2">
            <section id="mandate">
                <div className="container mx-auto bg-white shadow-lg rounded-lg p-6">
                    <h2 className="text-3xl font-semibold text-gray-700 mb-4">Mandate</h2>
                    <p className="mt-6">
                        {showFullMandate ? (
                            <>
                                Strengthen government’s control and regulation of schools below tertiary level in the State, through the standardization, monitoring and evaluation of both private and public schools

                                Maintain appropriate policies, guidelines and instruments for Quality Assurance Evaluation in the State

                                Registration, Documentation and Approval of all private institutions below tertiary level in the State

                                Demand and obtain relevant data and report on matters relating to Quality Assurance from all parastatals and Schools

                                Ensure quality and professionalism in the recruitment, training, placement and promotion of teachers

                                Development of a state wide and harmonious schools’ calendar in collaboration with stakeholders

                                Establishment of database of private institutions below tertiary level in the State which shall include all reports of assessment, approval, grading, etc

                                Quality Assurance Evaluation of Public and Private Education Institutions including the review and amendment of the school curriculum in collaboration with stakeholders

                                Accreditation of Schools for External Examinations

                                Production of Annual State of Education Report

                                Ranking of private schools and publishing same in the print and electronic media

                                Investigation of all matters affecting learners, parents, staff and management of educational institutions

                                Ensuring that every child is enrolled in school and receives qualitative education through collaboration with other stakeholders

                                Organizing special programmes and social awareness activities for the academic, social and moral development of learners

                                Producing and reviewing Guidelines on the Establishment of Private Institutions

                                Monitoring of internal and external examinations

                                Liaising with relevant agencies to ensure that the school environment of all registered schools is conducive and has adequate facilities for learning
                                <button onClick={toggleMandate} className="mt-4 text-blue-500 hover:underline">Read Less</button>
                            </>
                        ) : (
                            <>
                                Strengthen government’s control and regulation of schools below tertiary level in the State, through the standardization, monitoring and evaluation of both private and public schools...
                                <button onClick={toggleMandate} className="mt-4 text-blue-500 hover:underline">Read More</button>
                            </>
                        )}
                    </p>
                </div>
            </section>
            {/*  */}
            <section id="accreditation">
                <div className="container mx-auto bg-white shadow-lg rounded-lg p-6">
                    <h2 className="text-3xl font-semibold text-gray-700 mb-4">Accreditation</h2>
                    <p className="mt-6">
                        {showFullAccreditation ? (
                            <>
                                To get accredited, public/private primary or secondary schools must provide necessary documents, such as:
                                <ul className="mt-4 list-disc list-inside">
                                    <li>School policies and procedures, such as admissions policy, discipline policy, health and safety policies, etc.</li>
                                    <li>Curriculum: Include details of your school's curriculum, including scope and sequence, course syllabi, and any relevant supporting materials.</li>
                                    <li>Staff credentials and qualifications: Provide documents verifying the credentials and qualifications of your teaching staff, such as degrees, certifications, and licenses.</li>
                                    <li>Facilities and resources: Include details about your school's facilities, infrastructure, and resources, such as classrooms, libraries, laboratories, sports facilities, etc.</li>
                                    <li>Financial statements: Prepare financial statements, including income and expenditure reports, balance sheets, audits, and any other relevant financial documents.</li>
                                    <li>Student records: Document student enrollment numbers, attendance records, academic transcripts, and any other student-related information required.</li>
                                </ul>
                                <p className="mt-4">Site Visit and Inspection: After Documentation, depending on the accreditation process, a site visit may be conducted by an accreditation team. They will evaluate your school based on various criteria, such as curriculum implementation, facilities, teaching methods, and more.</p>
                                <p className="mt-4">Evaluation and Review: Following the site visit, the accreditation body will assess all the documents and information provided. They will review your school's compliance with the accreditation standards and determine if accreditation will be</p>
                                <button onClick={toggleAccreditation} className="mt-4 text-blue-500 hover:underline">Read Less</button>
                            </>
                        ) : (
                            <>
                                To get accredited, public/private primary or secondary schools must provide necessary documents documents, such as school policies and procedures, such as admissions policy...
                                <button onClick={toggleAccreditation} className="mt-4 text-blue-500 hover:underline">Read More</button>
                            </>
                        )}
                    </p>
                </div>
            </section>
        </div>
    );
};

export default Accreditation;
