import React, { useRef } from 'react';

const EnrollmentRow = ({ label, id, formData, handleInputChange }) => {
  const boysRef = useRef(null);
  const girlsRef = useRef(null);

  const handleEnrollmentChange = (e) => {
    const { name, value } = e.target;
    const updatedValue = parseInt(value, 10) || 0;

    handleInputChange({
      target: {
        name,
        value: updatedValue,
      },
    });

    const boysKey = `${id}_boys_enrolment`;
    const girlsKey = `${id}_girls_enrolment`;
    const totalKey = `${id}_total_enrolment`;

    handleInputChange({
      target: {
        name: totalKey,
        value: (name === boysKey ? updatedValue : formData[boysKey]) + 
               (name === girlsKey ? updatedValue : formData[girlsKey]),
      },
    });

    if (name === boysKey) {
      boysRef.current.focus();
    } else if (name === girlsKey) {
      girlsRef.current.focus();
    }
  };

  return (
    <div className="flex items-center mb-2">
      <div className="w-1/4 font-medium text-gray-700">{label}</div>
      <input
        type="number"
        name={`${id}_boys_enrolment`}
        value={formData[`${id}_boys_enrolment`] || ''}
        onChange={handleEnrollmentChange}
        placeholder="Boys"
        className="w-1/4 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mr-2"
        ref={boysRef}
      />
      <input
        type="number"
        name={`${id}_girls_enrolment`}
        value={formData[`${id}_girls_enrolment`] || ''}
        onChange={handleEnrollmentChange}
        placeholder="Girls"
        className="w-1/4 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mr-2"
        ref={girlsRef}
      />
      <input
        type="number"
        name={`${id}_total_enrolment`}
        value={formData[`${id}_total_enrolment`] || ''}
        placeholder="Total"
        className="w-1/4 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        readOnly
      />
    </div>
  );
};

export default EnrollmentRow;
