import React from 'react';


const newsArticles = [
  {
    id: 1,
    title: "Important Update from Benue State Bureau for Education Quality Assurance",
    description: "The Benue State Bureau for Education Quality Assurance is excited to announce the latest updates on developments in state education. For more details, please click on the links below.",
    pdfLink1: '/media/pdf/AN UPDATE OF UNAPPROVED SCHOOLS.pdf',
    pdfLink2: '/media/pdf/CERTIFICATE OF CONSENT (1ST BATCH).pdf',
    pdfLink3: '/media/pdf/SUMMARY FOR WASSCE AND NECO-1.pdf',
  },
  // {
  //   id: 2,
  //   title: 'Status of School Registrations',
  //   summary: 'Latest updates on the status of school registrations in Benue State. Find out which schools have successfully registered and those pending approval.',
  //   pdfLink: '/media/pdf/Academic-Calender-2023-2024-05.pdf',
  //   content: 'As part of our ongoing efforts to improve educational standards, we are providing updates on the status of school registrations across Benue State. Several schools have successfully completed the registration process and are now fully compliant with state regulations. However, there are still some schools whose registrations are pending approval. We encourage these schools to complete the necessary requirements as soon as possible.',
  // },
  // {
  //   id: 3,
  //   title: 'Quality Assurance Inspections',
  //   summary: 'Benue State Bureau for Education Quality Assurance conducts inspections to ensure schools meet the required educational standards.',
  //   pdfLink: '/media/pdf/Academic-Calender-2023-2024-05.pdf',
  //   content: 'In our continuous effort to maintain high educational standards, the Benue State Bureau for Education Quality Assurance has been conducting inspections across various schools in the state. These inspections are aimed at assessing the quality of education being provided and ensuring that schools meet the necessary criteria. Schools that fail to meet the standards will be given specific recommendations and timelines for improvement.',
  // },
  // {
  //   id: 4,
  //   title: 'Training Programs for Teachers',
  //   summary: 'Upcoming training programs organized by the Benue State Bureau for Education Quality Assurance to enhance the skills of teachers.',
  //   pdfLink: '/media/pdf/Academic-Calender-2023-2024-05.pdf',
  //   content: 'We are pleased to announce a series of training programs designed to enhance the skills and competencies of teachers in Benue State. These programs will cover various topics, including modern teaching methodologies, classroom management, and the use of technology in education. All teachers are encouraged to participate in these training sessions to improve their teaching practices and contribute to better learning outcomes for students.',
  // },
];


const NewsSection = () => {
  return (
    <section className="bg-white  md:pl-16 md:pt-16">
      <div className="container">
        <h2 className="text-3xl font-semibold  text-red-600">Latest News</h2>
        <div className="space-y-6">
          {newsArticles.map(article => (
            <div key={article.id} className="bg-white mt-4 ">
              {/* <h3 className="text-3xl font-semibold text-gray-700">{article.title}</h3> */}
              <p className="text-gray-700 mb-4">{article.description}</p>
              <ol className='grid'>
              <a href={article.pdfLink1} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:underline">List of Unapproved Private Schools</a>
              <a href={article.pdfLink2} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:underline">List of Private Schools with Full Approval</a>
              <a href={article.pdfLink3} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:underline">2024 WASSCE/NECO: Data of Schools Accreditated</a>

              </ol>
              
            </div>
          ))}
        </div>
      </div>
      <div className='mt-10 pr-8'>
      <iframe
            title="Introducing e-Dossier"
            className="rounded-2xl w-full"
            src="https://www.youtube.com/embed/oSuXxpRX0KM"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
      </div>
    </section>
  );
};

export default NewsSection;
