import React from 'react';
import Logo from '../img/logo.png'

const Footer = () => {
  return (
    
<footer className="bg-blue-950 text-white px-5 md:px-32">
  <div className="max-w-screen-xl  py-16 mx-auto ">
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
      <div>
      <img src={Logo} className="mr-5 h-6 sm:h-9" alt="logo" />
        <div className="max-w-xs mt-4 text-sm text-gray-400 ">
          <spa>Opposite Aper Aku Stadium,</spa> <br/>
        <span>Lawrence Onoja Crescent,</span> <br/>
        <span> Makurdi, Benue State.</span><br/>
        <span>+234 916 000 8820</span><br/>
        <span>info@beqa.be.gov.ng</span>
        </div>
       
      </div>
      <div className="grid grid-cols-3 gap-8 lg:col-span-2 sm:grid-cols-2 lg:grid-cols-4">
        <div>
          <p className="font-medium">
          Services
          </p>
          <nav className="flex flex-col mt-4 space-y-2 text-sm text-gray-400">
            <a className="hover:opacity-75" href> Accreditation </a>
            <a className="hover:opacity-75" href> Enumeration </a>
            <a className="hover:opacity-75" href> Executives </a>
          </nav>
        </div>
        
        <div>
          <p className="font-medium">
            Helpful Links
          </p>
          <nav className="flex flex-col mt-4 space-y-2 text-sm text-gray-400">
          <a className="hover:opacity-75" href> About Us </a>
            <a className="hover:opacity-75" href> Our Mandate </a>
            <a className="hover:opacity-75" href> Mission </a>
          </nav>
        </div>
        <div>
          <p className="font-medium ">
            Legal
          </p>
          <nav className="flex flex-col mt-4 space-y-2 text-sm text-gray-400">
            <a className="hover:opacity-75" href> Privacy Policy </a>
            <a className="hover:opacity-75" href> Terms &amp; Conditions </a>
            <a className="hover:opacity-75" href> Accessibility </a>
          </nav>
        </div>
      </div>
    </div>
    <p className="mt-8 text-xs text-gray-400">
      © 2024 Benue State Bureau for Education Quality Assurance
    </p>
  </div>
</footer>
    
  );
};

export default Footer;
