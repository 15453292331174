import React, { useState } from 'react';
import Logo from '../img/logo.png';
import { Link } from 'react-router-dom';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="w-full z-30 top-0 py-1 bg-blue-950 text-white">
      <div className="container mx-auto flex items-start justify-between mt-0 px-4 md:px-6 py-2">
        <Link to="/" className="flex items-center">
          <label htmlFor="menu-toggle" className="cursor-pointer md:hidden block" onClick={toggleMenu}>
            <svg className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <title>menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </label>
          <img src={Logo} alt="BEQA Logo" className="w-32 ml-3" />
        </Link>

        <div className={`md:hidden ${menuOpen ? 'hidden' : 'block'} pt-2`}>
          <Link to="/signin">
            <button className="bg-transparent text-white p-2 rounded border border-gray-300 hover:bg-gray-100 hover:text-gray-700">Sign in</button>
          </Link>
        </div>

        <div className={`md:flex md:items-center ${menuOpen ? 'block' : 'hidden'}`} id="menu">
          <nav className="mr-4">
            <ul className="md:flex items-center justify-between text-base pt-4 md:pt-0">
              <Link to='/' className="inline-block no-underline hover:underline font-semibold text-lg py-2 px-4 lg:-ml-2">
                Home
              </Link>
              <li>
              <div className="relative group">
                <button className="inline-block no-underline hover:underline font-semibold text-lg py-2 px-4 lg:-ml-2">
                Accreditation
                </button>
                <div className="hidden absolute w-52 bg-white shadow-lg rounded-lg  group-hover:block dropdown">
                  <div className="flex flex-col p-2">

                    <Link to="/media/pdf/AN UPDATE OF UNAPPROVED SCHOOLS.pdf" className="block px-4 py-2 text-sm text-gray-950 hover:bg-gray-100">List of Unapproved Private Schools</Link>
                    <Link to="/media/pdf/CERTIFICATE OF CONSENT (1ST BATCH).pdf" className="block px-4 py-2 text-sm text-gray-950 hover:bg-gray-100">List of Private Schools with Full Approval</Link>
                    <Link to="/media/pdf/SUMMARY FOR WASSCE AND NECO-1.pdf" className="block px-4 py-2 text-sm text-gray-950 hover:bg-gray-100">2024 WASSCE/NECO: Data of Schools Accreditated</Link>
                    {/* <Link to="/media/pdf/privatejunior.pdf" className="block px-4 py-2 text-sm text-gray-950 hover:bg-gray-100">BEQA News</Link>
                    <Link to="/media/pdf/privatejunior.pdf" className="block px-4 py-2 text-sm text-gray-950 hover:bg-gray-100">BEQA Gallery</Link> */}
                  </div>
                </div>
              </div>
              </li>
              <li>
                <a className="inline-block no-underline hover:underline font-semibold text-lg py-2 px-4 lg:-ml-2" href="#executives">Executives</a>
              </li>

              <div className="relative group">
                <button className="inline-block no-underline hover:underline font-semibold text-lg py-2 px-4 lg:-ml-2">
                  Media
                </button>
                <div className="hidden absolute w-52 bg-white shadow-lg rounded-lg  group-hover:block dropdown">
                  <div className="flex flex-col p-2">

                    <Link to="/media/pdf/Academic-Calender-2023-2024-05.pdf" className="block px-4 py-2 text-sm text-gray-950 hover:bg-gray-100">Academic Calendar 2023-2024</Link>
                    <Link to="/media/pdf/SSCE-Clerance-Guide-2024.pdf" className="block px-4 py-2 text-sm text-gray-950 hover:bg-gray-100">S.S.C.E Clearance Guide 2024</Link>
                    <Link to="/media/pdf/PRE-PRIMARY PRIMARY SCHOOL.pdf" className="block px-4 py-2 text-sm text-gray-950 hover:bg-gray-100">Pre-Primary School</Link>
                    <Link to="/media/pdf/PRIVATE JUNIOR SECONDARY SCHOOL.pdf" className="block px-4 py-2 text-sm text-gray-950 hover:bg-gray-100">Private Junior Secondary School</Link>
                    <Link to="/media/pdf/PRIVATE SENIOR SECONDARY SCHOOL.pdf" className="block px-4 py-2 text-sm text-gray-950 hover:bg-gray-100">Private Senior Secondary School</Link>
                    {/* <Link to="/media/pdf/privatejunior.pdf" className="block px-4 py-2 text-sm text-gray-950 hover:bg-gray-100">BEQA News</Link>
                    <Link to="/media/pdf/privatejunior.pdf" className="block px-4 py-2 text-sm text-gray-950 hover:bg-gray-100">BEQA Gallery</Link> */}
                  </div>
                </div>
              </div>

              
              <li className="block md:hidden px-4">
                <Link to="/signin">
                  <button className="bg-transparent text-white p-2 rounded border border-gray-300 hover:bg-gray-100 hover:text-gray-700">Sign in</button>
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className="hidden md:flex order-2 md:order-3 flex items-center justify-end mr-0 md:mr-4" id="nav-content">
          <div className="auth flex items-center w-full md:w-full">
            <Link to="/signin">
              <button className="bg-transparent text-white p-2 rounded border border-gray-300 mr-4 hover:bg-gray-100 hover:text-gray-700">Sign in</button>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
