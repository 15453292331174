import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../img/harmony-logo.png.xhtml;jsessionid=85A9F03585F57D4C6077C5AD05BEB7EF.png';
import ProfileDropdown from './ProfileDropdown';
import Dashboard from './Dashboard';
import GradingForm from './GradingForm';
import { useLocation } from 'react-router-dom';
import SchoolReport from './SchoolReport';
import TransactionsReport from './TransactionsReport';
import TransactionSummaryReport from './TransactionSummaryReport';
import RemitanceSummary from './RemitanceSummary';


const AdminPanel = () => {
  const location = useLocation();
  const profileData = location.state?.profileData;

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [clickDashboard, setClickDashboard] = useState(true)
  const [clickDataCaptured, setClickDataCaptured] = useState(false)
  const [clickSchoolReport, setSchoolReport] = useState(false)
  const [clickTransactionReport, setTransactionReport] = useState(false)
  const [dataCaptureOpen, setDataCaptureOpen] = useState(false);
  const [reportsOpen, setReportsOpen] = useState(false);
  const [transactionSummary, setTransactionSumary] = useState(false)
  const [remitanceSummary, setRemitanceSumary] = useState(false)


  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleDataCapture = () => setDataCaptureOpen(!dataCaptureOpen);
  const toggleReports = () => setReportsOpen(!reportsOpen);


  const handleMenuClick = (menu) => {
    switch (menu) {
      case 'dashboard': {
        setClickDashboard(clickDashboard ? clickDashboard : !clickDashboard)
        setClickDataCaptured(false)
        setSchoolReport(false)

        break
      }
    
      case 'grading': {
        setClickDataCaptured(clickDataCaptured ? clickDataCaptured : !clickDataCaptured)
        setClickDashboard(false)
        setSchoolReport(false)

        break
      }
      case 'school-report': {
        setSchoolReport(clickSchoolReport ? clickSchoolReport : !clickSchoolReport)
        setClickDashboard(false)
        setClickDataCaptured(false)
        setRemitanceSumary(false)
        setTransactionSumary(false)
        break
      }
      case 'transaction-report': {
        setTransactionReport(clickTransactionReport ? clickTransactionReport : !clickTransactionReport)
        setClickDashboard(false)
        setSchoolReport(false)
        setClickDataCaptured(false)
        setRemitanceSumary(false)
        setTransactionSumary(false)
        break
      }
        case 'transaction-summary':{
          setTransactionSumary(transactionSummary ? transactionSummary : !transactionSummary)
          setClickDashboard(false)
          setSchoolReport(false)
          setTransactionReport(false)
          setClickDataCaptured(false)
          setRemitanceSumary(false)
            break;
        }

        case 'remitance-summary':{
          setRemitanceSumary(remitanceSummary ? remitanceSummary : !remitanceSummary)
          setClickDashboard(false)
          setSchoolReport(false)
          setTransactionReport(false)
          setClickDataCaptured(false)
          setTransactionSumary(false)
            break;
        }
        
      
      default: {
      }
    }
  }

  return (
    <div className="min-h-screen flex flex-col">
      <nav className="bg-white border-b border-gray-200 fixed z-30 w-full">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start">
              <button
                id="toggleSidebarMobile"
                aria-expanded="true"
                aria-controls="sidebar"
                onClick={handleToggleSidebar}
                className="lg:hidden mr-2 text-gray-600 hover:text-gray-900 p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded"
              >
                <svg
                  id="toggleSidebarMobileHamburger"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 4h16v2H2V4zm0 5h16v2H2V9zm0 5h16v2H2v-2z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  id="toggleSidebarMobileClose"
                  className="w-6 h-6 hidden"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <Link to="/" className="text-xl font-bold flex items-center lg:ml-2.5">
                <img src={Logo} className="h-6 mr-2" alt="Windster Logo" />
                <span className="self-center whitespace-nowrap">BEQA</span>
              </Link>
              {/* <form action="#" method="GET" className="hidden lg:block lg:pl-32">
                <label htmlFor="topbar-search" className="sr-only">
                  Search
                </label>
                <div className="mt-1 relative lg:w-64">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a6 6 0 100 12 6 6 0 000-12zM8 2a8 8 0 100 16A8 8 0 008 2z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    name="email"
                    id="topbar-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full pl-10 p-2.5 focus:ring-cyan-600 focus:border-cyan-600"
                    placeholder="Search"
                  />
                </div>
              </form> */}
            </div>
            <div className="flex items-center">


              <ProfileDropdown profile={profileData} />
            </div>
          </div>
        </div>
      </nav>

      <div className="flex overflow-hidden h-screen  bg-white pt-16">

        <aside
          id="sidebar"
          className={`${isSidebarOpen ? 'block' : 'hidden'} lg:block w-72  bg-gray-50 border-r border-gray-200 h-full`}
          aria-label="Sidebar"
        >
          <div className="overflow-y-auto py-4 px-2 md:px-3 h-full">
            <ul className="space-y-2">
              <li>
                <div
                  onClick={() => handleMenuClick('dashboard')}
                  className="flex items-center cursor-pointer p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100"
                >
                  <svg
                    className="w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 2a8 8 0 100 16 8 8 0 000-16z"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="ml-3">Dashboard</span>
                </div>
              </li>
              <li>
                <div
                  onClick={toggleDataCapture}
                  className="flex items-center justify-between cursor-pointer p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100"
                >
                  <div className="flex items-center" onClick={() => handleMenuClick('data')}>
                    <svg
                      className="w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zM4 5a1 1 0 011-1h10a1 1 0 011 1v10a1 1 0 01-1 1H5a1 1 0 01-1-1V5z"
                        clipRule="evenodd"
                      ></path>
                      <path
                        fillRule="evenodd"
                        d="M8 7a2 2 0 100 4 2 2 0 000-4zm-3 8a5 5 0 0110 0H5z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="ml-3">Data Capture</span>
                  </div>
                  <svg
                    className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${dataCaptureOpen ? 'rotate-180' : ''}`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
                {dataCaptureOpen && (
                  <ul className="pl-8 space-y-2">
                    <li>
                      <div
                        onClick={() => handleMenuClick('grading')}
                        className="block px-4 py-2 text-gray-700 rounded-lg hover:bg-gray-200 cursor-pointer"
                      >
                        Grading Form
                      </div>
                    </li>
                    <li>
                      <div
                        onClick={() => handleMenuClick('enum')}
                        className="block px-4 py-2 text-gray-700 rounded-lg hover:bg-gray-200 cursor-pointer"
                      >
                        Staff Enumeration
                      </div>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <div
                  onClick={toggleReports}
                  className="flex items-center justify-between cursor-pointer p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100"
                >
                  <div className="flex items-center" onClick={() => handleMenuClick('report')}>
                    <svg
                      className="w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 3a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1H3a1 1 0 01-1-1V3zm1-1a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2H3z"
                        clipRule="evenodd"
                      ></path>
                      <path
                        fillRule="evenodd"
                        d="M4 8a1 1 0 000 2h12a1 1 0 100-2H4zm0 3a1 1 0 000 2h12a1 1 0 100-2H4zm0 3a1 1 0 000 2h8a1 1 0 100-2H4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="ml-3">Reports</span>
                  </div>
                  <svg
                    className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${reportsOpen ? 'rotate-180' : ''}`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
                {reportsOpen && (
                  <ul className="pl-8 space-y-2">
                    <li>
                      <div
                        onClick={() => handleMenuClick('school-report')}
                        className="block px-4 py-2 text-gray-700 rounded-lg hover:bg-gray-200 cursor-pointer"
                      >
                        Schools Report
                      </div>
                    </li>
                    {/* <li>
                      <div
                        onClick={() => handleMenuClick('school-report')}
                        className="block px-4 py-2 text-gray-700 rounded-lg hover:bg-gray-200 cursor-pointer"
                      >
                        Terminals Report
                      </div>
                    </li> */}
                    <li>
                      <div
                        onClick={() => handleMenuClick('transaction-report')}
                        className="block px-4 py-2 text-gray-700 rounded-lg hover:bg-gray-200 cursor-pointer"
                      >
                        Transactions Report
                      </div>
                    </li>
                    <li>
                      <div
                        onClick={() => handleMenuClick('transaction-summary')}
                        className="block px-4 py-2 text-gray-700 rounded-lg hover:bg-gray-200 cursor-pointer"
                      >
                        Transactions Summary
                      </div>
                    </li>
                    <li>
                      <div
                        onClick={() => handleMenuClick('remitance-summary')}
                        className="block px-4 py-2 text-gray-700 rounded-lg hover:bg-gray-200 cursor-pointer"
                      >
                        Remittance Summary
                      </div>
                    </li>
                    {/* <li>
                      <div
                        to="/reports/unremitted"
                        className="block px-4 py-2 text-gray-700 rounded-lg hover:bg-gray-200 cursor-pointer"
                      >
                        Unremitted
                      </div>
                    </li> */}
                  </ul>
                )}
              </li>
              {/* Add more menu items as needed */}
            </ul>
          </div>
        </aside>
        <div className="w-full overflow-y-auto">
          <main className="flex-grow p-4">
            {/* Main content goes here */}
            {
              clickDashboard ? (<Dashboard />) : clickDataCaptured ? (<GradingForm />) : clickSchoolReport ? (<SchoolReport />) : clickTransactionReport ? (<TransactionsReport/>) : transactionSummary ? (<TransactionSummaryReport />): remitanceSummary ? (<RemitanceSummary profileData={profileData}/>): 'Hello!!!'
            }
          </main>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
