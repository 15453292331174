// getProfile.js
// import { firestore } from '../firebase/firebase';
import app from '../firebase/firebase'

import { collection, query, where, getDocs,getFirestore } from 'firebase/firestore';

const getProfileByEmail = async (email) => {
    const db = getFirestore(app);
    try {
        const q = query(collection(db, 'profile'), where('email', '==', email));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.log('No matching documents.');
            return null;
        }

        let profile = null;
        querySnapshot.forEach((doc) => {
            profile = { id: doc.id, ...doc.data() };
        });

        return profile;
    } catch (error) {
        console.error('Error getting profile: ', error);
        return null;
    }
};

export default getProfileByEmail;
