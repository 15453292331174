import React, { useState, useEffect, useCallback } from 'react';
import RevenueCard from './RevenueCard';
import SchoolSummary from './SchoolSummary';
import TopStations from './TopStations';
import SchoolRegistrationSummaryByLGA from './SchoolRegistrationSummaryByLGA';
import app from '../firebase/firebase'
import { getFirestore, collection, getDocs, getCountFromServer } from "firebase/firestore";
import { getMonthNames } from './dateUtils'
import { useSyncLga } from './SyncLgaContext'




const db = getFirestore(app);
const Dashboard = () => {
  const syncLga = useSyncLga();
  const lgaData = [
    { lga: 'ADO', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'AGATU', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'APA', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'BURUKU', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'GBOKO', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'GUMA', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'GWER EAST', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'GWER WEST', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'KATSINA-ALA', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'KONSHISHA', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'KWANDE', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'LOGO', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'MAKURDI', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'OBI', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'OGBADIBO', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'OHIMINI', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'OJU', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'OKPOKWU', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'OTUKPO', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'TARKA', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'UKUM', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'USHONGO', schools: 0, girls: 0, boys: 0, total: 0 },
    { lga: 'VANDEIKYA', schools: 0, girls: 0, boys: 0, total: 0 },
  ];

  const [isLoading, setIsLoading] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [lgas, setlgas] = useState(lgaData)
  const [dataSum, setDataSum] = useState(null);
  const [currentMonth, setCurrentMonth] = useState('');
  const [previousMonth, setPreviousMonth] = useState('');
  const [topStations, setTopStations] = useState([])


  const fetchTotalRecordsFromFirestore = async () => {
    const coll = collection(db, 'grading');
    const snapshot = await getCountFromServer(coll);
    return snapshot.data().count;
  };

  // const getTotalRecords = useCallback(async () => {
  //   setIsLoading(true)
  //   const records = await fetchTotalRecordsFromFirestore();
  //   const cachedRecords = localStorage.getItem('totalRecords');

  //   if (cachedRecords !== null) {
  //     const cachedRecordsInt = parseInt(cachedRecords, 10);
  //     if (cachedRecordsInt !== records) {
  //       performAdditionalQuery(lgas);
  //     } else {
  //       const cachedlgas = localStorage.getItem('lgas');
  //       console.log('Hello:: ',cachedlgas)
  //       if ( cachedlgas !== undefined  && cachedlgas !== null) {
  //         const cachedcachedlgasRecords = JSON.parse(cachedlgas);
  //         setlgas(cachedcachedlgasRecords);
  //         setIsLoading(false)
  //       }else{
  //         performAdditionalQuery(lgas);
  //       }


  //     }
  //   } else {
  //     performAdditionalQuery(lgas);
  //   }

  //   setTotalRecords(records);
  //   localStorage.setItem('totalRecords', records);
  // }, []);

  const getTotalRecords = useCallback(async () => {
    setIsLoading(true);

    try {
      const records = await fetchTotalRecordsFromFirestore();
      const cachedRecords = localStorage.getItem('totalRecords');

      if (cachedRecords !== null) {
        const cachedRecordsInt = parseInt(cachedRecords, 10);
        if (cachedRecordsInt !== records) {
          performAdditionalQuery(lgas);
        } else {
          const cachedlgas = localStorage.getItem('lgas');

          if (cachedlgas) {
            try {
              const cachedlgasRecords = JSON.parse(cachedlgas);
              setlgas(cachedlgasRecords);
              setIsLoading(false);
            } catch (e) {
              console.error('Failed to parse cached lgas:', e);
              performAdditionalQuery(lgas);
            }
          } else {
            performAdditionalQuery(lgas);
          }
        }
      } else {
        performAdditionalQuery(lgas);
      }

      setTotalRecords(records);
      localStorage.setItem('totalRecords', records);
    } catch (error) {
      console.error('Failed to fetch records from Firestore:', error);
      // Handle error appropriately
    } finally {
      setIsLoading(false);
    }
  }, [lgas]);

  const performAdditionalQuery = useCallback(async (records) => {
    const additionalData = await updateLgasData(records);
    setlgas(additionalData);
    localStorage.setItem('lgas', JSON.stringify(additionalData));
    setIsLoading(false)
  }, []);

  useEffect(() => {
    const { currentMonthName, previousMonthName } = getMonthNames();
    setCurrentMonth(currentMonthName);
    setPreviousMonth(previousMonthName);

    fetchData();
    getTotalRecords()


  }, [totalRecords]);

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const response = await fetch('https://us-central1-igr-service.cloudfunctions.net/app/api/dashboard/summary/07');
      const result = await response.json();
      if (result.success) {
        setDataSum(result.result);
        setTopStations(result.result.topStations)
      }
      setIsLoading(false)
      // else {
      //   setError(result.message);
      // }
    } catch (error) {
      setIsLoading(false)
      console.error('Failed to fetch data:', error);
    }
  };


  const getLgaNameById = (id) => {
    const lga = syncLga.find(lga => lga.id === id);
    return lga ? lga.name.toLowerCase() : id.toLowerCase();
  };
  const updateLgasData = async (lgasArray) => {
    try {
      const querySnapshot = await getDocs(collection(db, "grading"));
      
      //STORED FOR REEPORT
      const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      localStorage.setItem('grading', JSON.stringify(data));
      //END

      const schoolsData = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const lga = data.lga_id ? getLgaNameById(data.lga_id) :data.lga.toLowerCase();

        if (!schoolsData[lga]) {
          schoolsData[lga] = {
            total_girls_enrolment: 0,
            total_boys_enrolment: 0,
            schools_count: 0
          };
        }

        schoolsData[lga].total_girls_enrolment += parseInt(data.total_girls_enrolment) || 0;
        schoolsData[lga].total_boys_enrolment += parseInt(data.total_boys_enrolment) || 0;
        schoolsData[lga].schools_count += 1;
        setIsLoading(false)
      });

      const updatedLgasArray = lgasArray.map(lgaItem => {
        const lga = lgaItem.lga.toLowerCase();
        const data = schoolsData[lga] || { total_girls_enrolment: 0, total_boys_enrolment: 0, schools_count: 0 };

        return {
          ...lgaItem,
          schools: data.schools_count,
          girls: data.total_girls_enrolment,
          boys: data.total_boys_enrolment,
          total: data.total_girls_enrolment + data.total_boys_enrolment
        };
      });

      //console.log('Updated LGAs Array:', updatedLgasArray);
      setlgas(updatedLgasArray)
      return updatedLgasArray;

    } catch (error) {
      console.error("Error fetching documents: ", error);
    }
  };


  const currentDateRevenue = {
    title: 'TODAY',
    total: dataSum ? dataSum.transactionTotals.today.todayTotal : 0,
    cash: dataSum ? dataSum.transactionTotals.today.todayTotalCash : 0,
    card: dataSum ? dataSum.transactionTotals.today.todayTotalCard : 0,
    index: 1
  };

  const currentMonthRevenue = {
    title: currentMonth.toUpperCase(),
    total: dataSum ? dataSum.transactionTotals.thisMonth.thisMonthTotal : 0,
    cash: dataSum ? dataSum.transactionTotals.thisMonth.thisMonthTotalCash : 0,
    card: dataSum ? dataSum.transactionTotals.thisMonth.thisMonthTotalCard : 0,
    index: 2
  };

  const previousMonthRevenue = {
    title: previousMonth.toUpperCase(),
    total: dataSum ? dataSum.transactionTotals.previousMonth.previousMonthTotal : 0,
    cash: dataSum ? dataSum.transactionTotals.previousMonth.previousMonthTotalCash : 0,
    card: dataSum ? dataSum.transactionTotals.previousMonth.previousMonthTotalCard : 0,
    index: 3
  };

  const inceptionRevenue = {
    title: 'CUMULATIVE TOTAL',
    total: dataSum ? dataSum.transactionTotals.overall.overallTotal : 0,
    cash: dataSum ? dataSum.transactionTotals.overall.overallTotalCash : 0,
    card: dataSum ? dataSum.transactionTotals.overall.overallTotalCard : 0,
    index: 4
  };

  const currentDate = 'June 2024';
  const previousMonths = [
    { name: 'May 2024', total: '$8,000' },
    { name: 'April 2024', total: '$7,500' }
  ];
  const cumulativeTotal = '$150,000';



  // if (isLoading) return <SkeletonLoading />
  return (
    <div className="  min-h-screen">
      <div className="container mx-auto">
        {/* <SyncGradingButton/> */}
        <h1 className="text-xl font-semibold mb-3">Revenue Summary</h1>
        <div className="grid grid-cols-1 md:grid-cols-4  gap-6">
          <RevenueCard {...currentDateRevenue} />
          <RevenueCard {...currentMonthRevenue} />
          <RevenueCard {...previousMonthRevenue} />
          <RevenueCard {...inceptionRevenue} />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <SchoolSummary
          totalRegistrations={totalRecords ? totalRecords : localStorage.getItem('totalRecords') !== null ? localStorage.getItem('totalRecords') : 0}
          completed={totalRecords ? totalRecords : localStorage.getItem('totalRecords') !== null ? localStorage.getItem('totalRecords') : 0}
          uncompleted={0}
        />

        <TopStations data={topStations} />
      </div>

      <div className="mt-8">
        <SchoolRegistrationSummaryByLGA data={lgas} />
      </div>
    </div>
  );
};

export default Dashboard;
