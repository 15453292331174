import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import 'tailwindcss/tailwind.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TopStations = ({ data }) => {
  const chartData = {
    labels: data.map(d => d.station),
    datasets: [
      {
        label: 'Amount (₦)',
        data: data.map(d => parseFloat(d.totalAmount.replace(/[₦,]/g, ''))), // Ensure totalAmount is numeric
        backgroundColor: 'rgba(66, 133, 244, 0.6)',
        yAxisID: 'y1',
      },
      {
        label: 'Transaction Counts',
        data: data.map(d => d.transactionCount),
        backgroundColor: 'rgba(15, 157, 88, 0.6)',
        yAxisID: 'y2',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        labels: data.map(d => d.station),
      },
      y1: {
        type: 'linear',
        position: 'left',
        beginAtZero: true,
        title: {
          display: true,
          text: 'Amount (₦)',
        },
      },
      y2: {
        type: 'linear',
        position: 'right',
        beginAtZero: true,
        title: {
          display: true,
          text: 'Transaction Counts',
        },
        grid: {
          drawOnChartArea: false, // This prevents the grid lines for y2 overlapping with y1
        },
      },
    },
  };

  return (
    <div className="bg-white shadow-md border border-gray-50 rounded-lg p-6 mb-8">
      <h3 className="text-xl font-semibold mb-4">Top Revenue Stations</h3>
      <Tabs>
        <TabList>
          <Tab>Chart</Tab>
          <Tab>Table</Tab>
        </TabList>

        <TabPanel>
          <div className="h-80">
            <Bar data={chartData} options={options} />
          </div>
        </TabPanel>
        
        <TabPanel>
          <div className="overflow-x-auto">
            <div className="max-h-64 overflow-y-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SN</th>
                    <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Station Name</th>
                    <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount (₦)</th>
                    <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transaction Counts</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data.map((item, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                      <td className="px-3 py-4 whitespace-nowrap">{index + 1}</td>
                      <td className="px-3 py-4 whitespace-nowrap">{item.station}</td>
                      <td className="px-3 py-4 whitespace-nowrap">{item.totalAmount}</td>
                      <td className="px-3 py-4 whitespace-nowrap">{item.transactionCount}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="bg-gray-50">
                  <tr>
                    <td className="px-3 py-4 whitespace-nowrap font-semibold">Total</td>
                    <td className="px-3 py-4 whitespace-nowrap"></td>
                    <td className="px-3 py-4 whitespace-nowrap">₦{data.reduce((sum, item) => sum + parseFloat(item.totalAmount.replace(/[₦,]/g, '')), 0).toLocaleString()}</td>
                    <td className="px-3 py-4 whitespace-nowrap">{data.reduce((sum, item) => sum + item.transactionCount, 0)}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default TopStations;
