import React, { useState, useEffect, useCallback, useRef } from 'react';
import app from '../firebase/firebase'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { getFirestore, collection, addDoc } from "firebase/firestore";
import EnrollmentRow from './EnrollmentRow'
import CheckboxWithFileInput from './CheckboxWithFileInput';
const db = getFirestore(app);


const GradingForm = () => {
    const [isLoading, setIsLoading] = useState(false)

    const [formData, setFormData] = useState({
        school_names: '',
        prev_school_names: '',
        school_address: '',
        nearest_landmark: '',
        lga: '',
        lga_id: '',
        school_email: '',
        proprietors_name: '',
        school_phone_nos: '',
        year_of_establishment: '',
        no_teachers_male: '',
        no_teachers_female: '',
        no_teachers_pwd: '',

        no_qualified_teachers_ssce: '',
        no_qualified_teachers_nce: '',
        no_qualified_teachers_diploma: '',
        no_qualified_teachers_hnd: '',
        no_qualified_teachers_degree: '',
        no_qualified_teachers_masters: '',
        no_qualified_teachers_phd: '',

        no_teachers_with_pwd_skill_male: '',
        no_teachers_with_pwd_skill_female: '',
        no_teachers_with_pwd_skill_pwd: '',

        no_usable_classrooms: '',
        no_streams: '',
        multigrade_teaching: false,
        fencing: false,


        staff_functional_toilets_female: '',
        staff_functional_toilets_male: '',
        staff_functional_toilets_pwd: '',

        learners_functional_toilets_female: '',
        learners_functional_toilets_male: '',
        learners_functional_toilets_pwd: '',
        pipe_borne_water: false,
        borehole: false,
        well: false,
        no_source_of_water: false,
        phcn: false,
        solar: false,
        generator: false,
        no_source_of_electricity: false,
        // 
        eccde_boys_enrolment: '',
        eccde_girls_enrolment: '',
        eccde_total_enrolment: '',
        lower_basic_boys_enrolment: '',
        lower_basic_girls_enrolment: '',
        lower_basic_total_enrolment: '',
        middle_basic_boys_enrolment: '',
        middle_basic_girls_enrolment: '',
        middle_basic_total_enrolment: '',
        upper_basic_boys_enrolment: '',
        upper_basic_girls_enrolment: '',
        upper_basic_total_enrolment: '',
        post_basic_boys_enrolment: '',
        post_basic_girls_enrolment: '',
        post_basic_total_enrolment: '',
        total_boys_enrolment: '',
        total_girls_enrolment: '',

        // Tuition
        eccde_first_tuition: false,
        eccde_second_tuition: false,
        eccde_third_tuition: false,
        eccde_fourth_tuition: false,
        lower_basic_first_tuition: false,
        lower_basic_second_tuition: false,
        lower_basic_third_tuition: false,
        lower_basic_fourth_tuition: false,
        middle_basic_first_tuition: false,
        middle_basic_second_tuition: false,
        middle_basic_third_tuition: false,
        middle_basic_fourth_tuition: false,
        upper_basic_first_tuition: false,
        upper_basic_second_tuition: false,
        upper_basic_third_tuition: false,
        upper_basic_fourth_tuition: false,
        post_basic_first_tuition: false,
        post_basic_second_tuition: false,
        post_basic_third_tuition: false,
        post_basic_fourth_tuition: false,
        // 
        napps: false,
        amis: false,
        parent_teacher_association: false,
        nigerian_union_of_teachers: false,
        nassp: false,
        ancopss: false,
        asuss: false,
        aopsht: false,
        catholic_edu_services: false,
        nkst: false,
        association_others: false,
        association_none: false,
        // 

        // 
        guidline_purchased: null,
        name_search: null,
        formal_application: null,
        site_inspection: null,
        take_off_permit: null,
        approval_inspection: null,
        provisional_approval: null,
        payment_accreditation_fee: null,
        full_approval: null,
        annual_renewal_year2021: null,
        annual_renewal_year2022: null,
        annual_renewal_year2023: null,
        school_ownership_public: null,
        school_ownership_private: null,
        school_ownership_single_private_owner: null,
        school_ownership_two_or_more_private_owners: null,
        school_ownership_religious_foundation: null,
        school_ownership_non_religious_foundation: null,
        school_ownership_others: null,
        // 
        school_admin_score: '',
        school_leadership_score: '',
        school_mentoring_score: '',
        school_teachers_development_score: '',
        school_governance_score: '',
        school_mechanisms_score: '',
        school_learners_score: '',
        school_community_score: '',
        school_development_score: '',
        school_leaners_health_score: '',
        school_learner_hygiene_score: '',
        school_learner_mealtime_score: '',
        school_child_protection_score: '',
        school_learners_security_score: '',
        school_playtime_supervision_score: '',
        school_promiting_girl_child_score: '',
        school_lpastoral_care_score: '',
        school_reward_sanction_score: '',
        school_learner_attendance_score: '',
        school_teachers_attendance_score: '',
        school_lesson_times_score: '',
        school_continuous_assessment_score: '',
        school_curricular_activities_score: '',
        school_learners_safety_score: '',
        school_building_maintenance_score: '',
        school_classes_taught_in_same_room_score: '',
        school_space_in_classroom_score: '',
        school_sufficient_toilets_score: '',
        school_premises_suitable_score: '',
        school_curriculum_planning_score: '',
        school_learning_resources_score: '',
        school_consumables_score: '',
        school_ventilation_score: '',
        school_teaching_materials_score: '',
        school_learning_materials_score: '',
        head_teacher_name: '',
        head_teacher_phone_number: '',
    });



    useEffect(() => {
        const totalBoysEnrolment =
            (formData.eccde_boys_enrolment || 0) +
            (formData.lower_basic_boys_enrolment || 0) +
            (formData.middle_basic_boys_enrolment || 0) +
            (formData.upper_basic_boys_enrolment || 0) +
            (formData.post_basic_boys_enrolment || 0);

        const totalGirlsEnrolment =
            (formData.eccde_girls_enrolment || 0) +
            (formData.lower_basic_girls_enrolment || 0) +
            (formData.middle_basic_girls_enrolment || 0) +
            (formData.upper_basic_girls_enrolment || 0) +
            (formData.post_basic_girls_enrolment || 0);

        setFormData((prevData) => ({
            ...prevData,
            total_boys_enrolment: totalBoysEnrolment,
            total_girls_enrolment: totalGirlsEnrolment,
        }));
    }, [
        formData.eccde_boys_enrolment,
        formData.lower_basic_boys_enrolment,
        formData.middle_basic_boys_enrolment,
        formData.upper_basic_boys_enrolment,
        formData.post_basic_boys_enrolment,
        formData.eccde_girls_enrolment,
        formData.lower_basic_girls_enrolment,
        formData.middle_basic_girls_enrolment,
        formData.upper_basic_girls_enrolment,
        formData.post_basic_girls_enrolment,
    ]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,

        }));
    };


    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: checked
        });
    };

    const handleRadioChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            const cachedRecords = localStorage.getItem('totalRecords') ? localStorage.getItem('totalRecords') : '';
            const cachedRecordsInt = parseInt(cachedRecords, 10);
            const newRecord = {
                ...formData,
                id: cachedRecordsInt + 1,
                lga_id: getLgaId(formData['lga']),
                date: new Date().toISOString()
            };


            await addDoc(collection(db, 'grading'), newRecord);
            NotificationManager.info('Form submitted successfully!');
            setIsLoading(false)
            clearForm()
        } catch (error) {
            console.error('Error adding document: ', error);
            NotificationManager.error('Error adding document')
            //alert('Error submitting form. Please try again.');
        }
    };

    const getLgaId = (lgaName) => {
        return benueLGAs[lgaName] || null;
    };
    const benueLGAs = {
        "ado": "1BN",
        "agatu": "2BN",
        "apa": "3BN",
        "buruku": "4BN",
        "gboko": "5BN",
        "guma": "6BN",
        "gwer East": "7BN",
        "gwer West": "8BN",
        "katsina-Ala": "9BN",
        "konshisha": "10BN",
        "kwande": "11BN",
        "logo": "12BN",
        "makurdi": "13BN",
        "obi": "14BN",
        "ogbadibo": "15BN",
        "ohimini": "16BN",
        "oju": "17BN",
        "okpokwu": "18BN",
        "otukpo": "19BN",
        "tarka": "20BN",
        "ukum": "21BN",
        "ushongo": "22BN",
        "vandeikya": "23BN"
    };

    const clearForm = () => (
        setFormData(
            {
                school_names: '',
                prev_school_names: '',
                school_address: '',
                nearest_landmark: '',
                lga: '',
                lga_id: '',
                school_email: '',
                proprietors_name: '',
                school_phone_nos: '',
                year_of_establishment: '',
                no_teachers_male: '',
                no_teachers_female: '',
                no_teachers_pwd: '',

                no_qualified_teachers_ssce: '',
                no_qualified_teachers_nce: '',
                no_qualified_teachers_diploma: '',
                no_qualified_teachers_hnd: '',
                no_qualified_teachers_degree: '',
                no_qualified_teachers_masters: '',
                no_qualified_teachers_phd: '',

                no_teachers_with_pwd_skill_male: '',
                no_teachers_with_pwd_skill_female: '',
                no_teachers_with_pwd_skill_pwd: '',

                no_usable_classrooms: '',
                no_streams: '',
                multigrade_teaching: false,
                fencing: false,
                staff_functional_toilets_female: '',
                staff_functional_toilets_male: '',
                staff_functional_toilets_pwd: '',

                learners_functional_toilets_female: '',
                learners_functional_toilets_male: '',
                learners_functional_toilets_pwd: '',
                pipe_borne_water: false,
                borehole: false,
                well: false,
                no_source_of_water: false,
                phcn: false,
                solar: false,
                generator: false,
                no_source_of_electricity: false,
                // 
                eccde_boys_enrolment: '',
                eccde_girls_enrolment: '',
                eccde_total_enrolment: '',
                lower_basic_boys_enrolment: '',
                lower_basic_girls_enrolment: '',
                lower_basic_total_enrolment: '',
                middle_basic_boys_enrolment: '',
                middle_basic_girls_enrolment: '',
                middle_basic_total_enrolment: '',
                upper_basic_boys_enrolment: '',
                upper_basic_girls_enrolment: '',
                upper_basic_total_enrolment: '',
                post_basic_boys_enrolment: '',
                post_basic_girls_enrolment: '',
                post_basic_total_enrolment: '',
                total_boys_enrolment: '',
                total_girls_enrolment: '',

                // 
                napps: false,
                amis: false,
                parent_teacher_association: false,
                nigerian_union_of_teachers: false,
                nassp: false,
                ancopss: false,
                asuss: false,
                aopsht: false,
                catholic_edu_services: false,
                nkst: false,
                association_others: false,
                association_none: false,
                // 

                // 
                guidline_purchased: null,
                name_search: null,
                formal_application: null,
                site_inspection: null,
                take_off_permit: null,
                approval_inspection: null,
                provisional_approval: null,
                payment_accreditation_fee: null,
                full_approval: null,
                annual_renewal_year2021: null,
                annual_renewal_year2022: null,
                annual_renewal_year2023: null,
                school_ownership_public: null,
                school_ownership_private: null,
                school_ownership_single_private_owner: null,
                school_ownership_two_or_more_private_owners: null,
                school_ownership_religious_foundation: null,
                school_ownership_non_religious_foundation: null,
                school_ownership_others: null,
                // 
                school_admin_score: '',
                school_leadership_score: '',
                school_mentoring_score: '',
                school_teachers_development_score: '',
                school_governance_score: '',
                school_mechanisms_score: '',
                school_learners_score: '',
                school_community_score: '',
                school_development_score: '',
                school_leaners_health_score: '',
                school_learner_hygiene_score: '',
                school_learner_mealtime_score: '',
                school_child_protection_score: '',
                school_learners_security_score: '',
                school_playtime_supervision_score: '',
                school_promiting_girl_child_score: '',
                school_lpastoral_care_score: '',
                school_reward_sanction_score: '',
                school_learner_attendance_score: '',
                school_teachers_attendance_score: '',
                school_lesson_times_score: '',
                school_continuous_assessment_score: '',
                school_curricular_activities_score: '',
                school_learners_safety_score: '',
                school_building_maintenance_score: '',
                school_classes_taught_in_same_room_score: '',
                school_space_in_classroom_score: '',
                school_sufficient_toilets_score: '',
                school_premises_suitable_score: '',
                school_curriculum_planning_score: '',
                school_learning_resources_score: '',
                school_consumables_score: '',
                school_ventilation_score: '',
                school_teaching_materials_score: '',
                school_learning_materials_score: '',
                head_teacher_name: '',
                head_teacher_phone_number: '',
            }
        )
    );
    const renderInputField = (label, type, name, placeholder, required = false) => (
        <div>
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <input
                type={type}
                name={name}
                value={formData[name]}
                placeholder={placeholder}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                required={required}
                onChange={handleInputChange}
            />
        </div>
    );

    const renderRadioGroup = (label, name, options) => {
        return (
            <div className="mb-4 grid grid-flow-col">
                <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
                {options.map(option => (
                    <div key={`${name}_${option}`} className="flex items-center mb-2">
                        <input
                            type="radio"
                            name={name}
                            id={`${name}_${option}`}
                            value={option}
                            checked={formData[name] === option}
                            onChange={handleRadioChange}
                            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                        <label htmlFor={`${name}_${option}`} className="ml-2 text-sm text-gray-600">{option}</label>
                    </div>
                ))}
            </div>
        );
    };


    const renderRadioGroupTrueFalse = (label, name, options) => {
        return (
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
                {options.map(option => (
                    <div key={`${name}_${option}`} className="flex items-center mb-2">
                        <input
                            type="radio"
                            name={name}
                            id={`${name}_${option}`}
                            value={option}
                            checked={formData[name] === (option === 'true')}
                            onChange={handleRadioChange}
                            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                        <label htmlFor={`${name}_${option}`} className="ml-2 text-sm text-gray-600">{option}</label>
                    </div>
                ))}
            </div>
        );
    };



    const renderCheckboxGroup = (label, namePrefix, options) => {
        return (
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
                {options.map(option => {
                    const optionKey = option.toLowerCase().replace(/ /g, '_'); // e.g., "Pipe borne water" -> "pipe_borne_water"
                    return (
                        <div key={optionKey} className="flex items-center mb-2">
                            <input
                                type="checkbox"
                                name={optionKey}
                                id={optionKey}
                                checked={formData[optionKey]}
                                onChange={handleCheckboxChange}
                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                            <label htmlFor={optionKey} className="ml-2 text-sm text-gray-600">{option}</label>
                        </div>
                    );
                })}
            </div>
        );
    };


    const renderSelectField = (label, name, options, required = false) => (
        <div>
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <select
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                required={required}
                name={name}
                value={formData[name]}
                onChange={handleInputChange}
            >
                <option value="">Select {label}</option>
                {options.map(option => (
                    <option key={option} value={option.toLowerCase()}>{option}</option>
                ))}
            </select>
        </div>
    );



    const section = ({ title, children }) => (
        <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg my-5'>
            <legend className="text-xl font-bold  uppercase">{title}</legend>
            {children}
        </fieldset>
    );


    const handleInputChangeEnrollmentRow = useCallback((e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }, []);




    // const CheckboxRow = ({ label, id, formData, handleInputChange }) => {
    //     return (
    //         <div className="flex items-center mb-2">
    //             <div className="w-1/4 text-gray-700">{label}</div>
    //             <input
    //                 type="checkbox"
    //                 name={`${id}_first_tuition`}
    //                 checked={formData[`${id}_first_tuition`] || false}
    //                 onChange={handleInputChange}
    //                 className="mr-2"
    //             />
    //             <label htmlFor={`${id}_first_tuition`} className="w-1/4 text-center"></label>

    //             <input
    //                 type="checkbox"
    //                 name={`${id}_second_tuition`}
    //                 checked={formData[`${id}_second_tuition`] || false}
    //                 onChange={handleInputChange}
    //                 className="mr-2"
    //             />
    //             <label htmlFor={`${id}_second_tuition`} className="w-1/4 text-center"></label>

    //             <input
    //                 type="checkbox"
    //                 name={`${id}_third_tuition`}
    //                 checked={formData[`${id}_third_tuition`] || false}
    //                 onChange={handleInputChange}
    //                 className="mr-2"
    //             />
    //             <label htmlFor={`${id}_third_tuition`} className="w-1/4 text-center"></label>

    //             <input
    //                 type="checkbox"
    //                 name={`${id}_fourth_tuition`}
    //                 checked={formData[`${id}_fourth_tuition`] || false}
    //                 onChange={handleInputChange}
    //                 className="mr-2"
    //             />
    //             <label htmlFor={`${id}_fourth_tuition`} className="w-1/4 text-center"></label>
    //         </div>
    //     );
    // };

    const CheckboxRow = ({ label, id, formData, handleInputChange }) => {
        const handleCheckboxChange = (e) => {
            const { name, checked } = e.target;
            handleInputChange({
                target: {
                    name,
                    value: checked,
                },
            });
        };

        return (
            <div className="flex items-center mb-2">
                <div className="w-1/4 text-gray-700">{label}</div>
                <input
                    type="checkbox"
                    name={`${id}_first_tuition`}
                    checked={formData[`${id}_first_tuition`] || false}
                    onChange={handleCheckboxChange}
                    className="mr-2"
                />
                <label htmlFor={`${id}_first_tuition`} className="w-1/4 text-center"></label>

                <input
                    type="checkbox"
                    name={`${id}_second_tuition`}
                    checked={formData[`${id}_second_tuition`] || false}
                    onChange={handleCheckboxChange}
                    className="mr-2"
                />
                <label htmlFor={`${id}_second_tuition`} className="w-1/4 text-center"></label>

                <input
                    type="checkbox"
                    name={`${id}_third_tuition`}
                    checked={formData[`${id}_third_tuition`] || false}
                    onChange={handleCheckboxChange}
                    className="mr-2"
                />
                <label htmlFor={`${id}_third_tuition`} className="w-1/4 text-center"></label>

                <input
                    type="checkbox"
                    name={`${id}_fourth_tuition`}
                    checked={formData[`${id}_fourth_tuition`] || false}
                    onChange={handleCheckboxChange}
                    className="mr-2"
                />
                <label htmlFor={`${id}_fourth_tuition`} className="w-1/4 text-center"></label>
            </div>
        );
    };


    const TuitionFeesForm = ({ formData, handleInputChange }) => {
        return (
            <fieldset className="border border-gray-300 p-4 shadow-md rounded-lg mb-2">
                <legend className="text-xl font-bold mb-2 uppercase">Tuition Fee (Private School Only)</legend>

                <p className=' mb-2'>
                    This should include any compulsory, regular, additional levies such as maintenance but should exclude optional levies such as transport.
                    Please tick the columns which best applies to your school. The amounts are per child per annum (Private School Only).
                </p>
                <div className="mb-2 text-sm text-gray-700 grid grid-cols-5 gap-2">
                    <span>Class</span>
                    <span>₦25,000 or below</span>
                    <span>₦25,001 – ₦50,000</span>
                    <span>₦50,001 – ₦100,000</span>
                    <span>over ₦100,000</span>
                </div>
                <CheckboxRow label="ECCDE" id="eccde" formData={formData} handleInputChange={handleInputChange} />
                <CheckboxRow label="Lower Basic" id="lower_basic" formData={formData} handleInputChange={handleInputChange} />
                <CheckboxRow label="Middle Basic" id="middle_basic" formData={formData} handleInputChange={handleInputChange} />
                <CheckboxRow label="Upper Basic" id="upper_basic" formData={formData} handleInputChange={handleInputChange} />
                <CheckboxRow label="Post Basic" id="post_basic" formData={formData} handleInputChange={handleInputChange} />
            </fieldset>
        );
    };



    const CheckboxAssociation = ({ id, label }) => (
        <div className="flex items-center mb-2">
            <input
                type="checkbox"
                id={id}
                className="mr-2"
            />
            <label htmlFor={id} className="text-sm  text-gray-700">{label}</label>
        </div>
    );

    const AssociationsForm = () => {
        return (
            <fieldset className="border border-gray-300 p-4 shadow-md rounded-lg mb-2">
                <legend className="text-xl font-bold mb-2 uppercase">Members Of Associations</legend>
                <p className="mb-2">
                    Please Indicate Which Of The Following Registered Associations Your School Belongs to. You Can Tick As Many Boxes As Apply.
                </p>
                <CheckboxAssociation
                    id="napps"
                    label="National Association of Proprietors of Private Schools (NAPPS)"
                    formData={formData}
                    setFormData={setFormData}
                />
                <CheckboxAssociation
                    id="amis"
                    label="Association Of Model Islamic Schools (amis)"
                    formData={formData}
                    setFormData={setFormData}
                />
                <CheckboxAssociation
                    id="parent_teacher_association"
                    label="Parent-teacher Association (PTA)"
                    formData={formData}
                    setFormData={setFormData}
                />
                <CheckboxAssociation
                    id="nigerian_union_of_teachers"
                    label="Nigerian Union Of Teachers (NUT)"
                    formData={formData}
                    setFormData={setFormData}
                />
                <CheckboxAssociation
                    id="nassp"
                    label="National Association Of Secondary School Teachers (NASSP)"
                    formData={formData}
                    setFormData={setFormData}
                />
                <CheckboxAssociation
                    id="ancopss"
                    label="All Nigerian Confederation of Principals of Secondary Schools (ANCOPSS)"
                    formData={formData}
                    setFormData={setFormData}
                />
                <CheckboxAssociation
                    id="asuss"
                    label="Academic Staff Union Of Secondary Schools (ASUSS)"
                    formData={formData}
                    setFormData={setFormData}
                />
                <CheckboxAssociation
                    id="aopsht"
                    label="Association Of Primary Schools Head Teacher Of Nigeria"
                    formData={formData}
                    setFormData={setFormData}
                />
                <CheckboxAssociation
                    id="catholic_edu_services"
                    label="Catholic Education Services"
                    formData={formData}
                    setFormData={setFormData}
                />
                <CheckboxAssociation
                    id="nkst"
                    label="NKST Education Services"
                    formData={formData}
                    setFormData={setFormData}
                />
                <CheckboxAssociation
                    id="association_others"
                    label="Others (Indicate)"
                    formData={formData}
                    setFormData={setFormData}
                />
                <CheckboxAssociation
                    id="association_none"
                    label="None"
                    formData={formData}
                    setFormData={setFormData}
                />
            </fieldset>

        );
    };



    const renderOrderedList = (label, items) => (
        <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
            <ol className="list-decimal pl-6">
                {items.map((item, index) => (
                    <li key={index} className="mb-1">{item}</li>
                ))}
            </ol>
        </div>
    );
    const lessonObservationEvidenceItems = [
        "Written communication to teachers informing them of the intended observation",
        "Guidelines for observer",
        "Reports of lesson observation",
        "Files with reports on the improvements observed in each teacher"
    ];
    const schoolManagementEvidenceItems = [
        "Staff have copies of their roles and responsibilities that show who they report to and who they supervise",
        "The school organisational structure for academic and non-academic staff",
        "Minutes of management meetings",
    ];
    const internalSupportEvidenceItems = [
        "Minutes of meetings between management and teachers that discusses issues affecting teaching and learning; and trainings done during the meeting.",
        "Teacher training plan",
        "Teacher development attendance register",
        "Records of discussions between teachers with clear action points",
        "Lists of teachers assigned as mentors and the teachers they are to support.",
        "Copies of the formal discussions on professional development kept in the teachers’ files."
    ];

    const externalTrainingEvidenceItems = [
        "Letters from teachers requesting permission and/ or support for external training.",
        "Teacher training plan"
    ];
    const schoolGovernanceEvidenceItems = [
        "Constitution of the independent governing body stating their roles and responsibilities",
        "Minutes of meetings with the independent governing body documenting consultations by school management and major decisions taken and implemented"
    ];

    const feedbackMechanismEvidenceItems = [
        "Minutes of PTA meetings documenting feedback to parents, their suggestions and comments",
        "Minutes of PTA meetings documenting actions taken following suggestions and comments from parents",
        "Copies of Learners’ report cards",
        "Attendance registers for school open day or curriculum day",
        "Letters of invitation/Newsletters to parents for the open day",
        "Documented procedure for complaints shared with parents",
        "File containing records of complaint and, actions taken",
        "Communication book between teachers and parents",
        "Text message, e-mail, WhatsApp chat, etc. to school/parent"
    ];

    const learnerParticipationEvidenceItems = [
        "School policy on learner participation",
        "Constitution of the student council (prefects) or other student body",
        "Minutes of meetings of the student council or other student body",
        "Minutes of school management meeting with student council members or other student body",
        "Suggestion/box and feedback questionnaires",
        "Pictures"
    ];
    const communityRelationsEvidenceItems = [
        "Minutes of community meetings e.g. Landlord/Residents Association",
        "Membership and constitution of governing council",
        "Payment for community security services",
        "Letters to and from the community",
        "Email, WhatsApp chat, text message, pictures"
    ];
    const schoolDevelopmentEvidenceItems = [
        "School vision, mission and core values",
        "School annual development plan",
        "List of attendees and reports from meetings to review the school’s progress",
        "Minutes of meetings showing discussions between the proprietor and staff; school management and parents to discuss the needs of the school",
        "Analysis of learners’ annual results"
    ];

    const learnerHealthEvidenceItems = [
        "Certificates of health personnel and teachers trained to provide first aid",
        "Contractual agreement between the school and health service provider",
        "First Aid box"
    ];

    const learnerHygieneEvidenceItems = [
        "Posted signs on personal hygiene",
        "Learners’ workbook showing teaching on personal hygiene",
        "Guidelines on learner hygiene for staff",
        "Functional toilets",
        "Safe water"
    ];

    const learnerMealtimeEvidenceItems = [
        "School meal timetable/menu",
        "Guidelines for food vendors and caterers for hygienic preparation, sale and distribution of school meals.",
        "Certificates of medical fitness of food vendors",
        "Written assessment to providers on the quality of food",
        "Meal sections in the duty report book"
    ];

    const childProtectionEvidenceItems = [
        "School policy on child protection",
        "Letters, newsletters or minutes of meeting sharing the policy",
        "Lesson plans/Workbooks covering topics on child protection"
    ];

    const securityEnvironmentEvidenceItems = [
        "Certificates or materials on training on learner safety for teachers and Drivers",
        "Identification given to/received from parents/Notice of change received from parents",
        "List of children using school transport and duty roster of staff accompanying/supervising transport"
    ];

    const playtimeSupervisionEvidenceItems = [
        "Duty roster",
        "List of responsibilities for the teacher on duty"
    ];

    const girlChildEducationEvidenceItems = [
        "Policy and/or guidelines on sexual harassment",
        "Lesson plans",
        "School programme"
    ];

    const pastoralCareEvidenceItems = [
        "Learner report cards",
        "Guidance and counselling policy",
        "Teacher records on learner problem solving",
        "Communication between school and parents on issues around their child"
    ];

    const rewardsSanctionsEvidenceItems = [
        "Rewards and Sanctions Policy",
        "Learners’ Conduct in and outside the class and Commendation Book",
        "Star charts in the classrooms"
    ];

    const learnerAttendanceEvidenceItems = [
        "Signed learners’ attendance registers",
        "Written feedback or report to supervisor on follow up with parents/guardians of absentees",
        "Reports on analysis of patterns of absenteeism"
    ];

    const teacherAttendanceEvidenceItems = [
        "Completed teachers’ time book and / or movement book",
        "Documented follow up on absent/unpunctual teachers",
        "Documented consequences for lateness or absenteeism in e.g. Staff handbook or letter of employment"
    ];

    const lessonTimesEvidenceItems = [
        "Current timetable posted on classroom walls",
        "Minutes of meetings or reports documenting teachers’ compliance with the timetable",
        "Teachers’ lesson plans checked against learners’ notebooks",
        "Physical contact with learners for enquiries about teacher attendance"
    ];

    const continuousAssessmentEvidenceItems = [
        "Continuous Assessment records",
        "Learners’ workbook",
        "Assessment timetable",
        "Lesson plans",
        "Documented learning targets and achievement steps/actions",
        "Marked examination/test sheets"
    ];

    const cocurricularActivitiesEvidenceItems = [
        "Attendance registers for club",
        "Newsletters/Notices to parents about school trips",
        "Minutes of PTA meeting mentioning Co-curricular activities",
        "Pictures / memorabilia",
        "Club membership list",
        "School timetable indicating periods & location for Co-curricular activities",
        "Notice to teachers/Roster assigning supervisory responsibility for Cocurricular activities"
    ];
    const schoolEnvironmentSafetyEvidenceItems = [
        "Certificates of staff trained on fire safety",
        "Maintenance/ Repair records",
        "Policy and procedure for fire drills",
        "Portable water",
        "Functional toilets",
        "Source of functional power supply",
        "Availability of child and disability friendly infrastructure"
    ];

    const schoolBuildingMaintenanceEvidenceItems = [
        "Maintenance Policy and/ or Maintenance/ Repair records"
    ];

    const schoolBuildingsAdequacyEvidenceItems = [
        "Approved building plans",
        "School site plan",
        "Land documents e.g. certificate of occupancy, title deed, survey plan",
        "Tenancy agreement"
    ];

    const curriculumApplicationEvidenceItems = [
        "Curriculum and approved schemes of work",
        "Lesson plans signed by supervisor",
        "Reports/Written feedback on review of lesson plans",
        "Reports/Written feedback on review of Learners’ notebooks against plans",
        "School Calendar showing planning week"
    ];
    const teachingResourcesEvidenceItems = [
        "For each class a list of teachers and number of learners",
        "Attendance registers and time book",
        "Teacher distribution list"
    ];

    const classroomEnvironmentEvidenceItems = [
        // Define specific evidence items for classroom environment if needed
    ];
    const instructionalMaterialsEvidenceItems = [
        "Lesson plans",
        "Flash cards, real life objects, aids for role play, etc.",
        "Puzzles and games",
        "Wall displays",
        "DVDs",
        "Science equipment",
        "Sports equipment"
    ];

    const learningMaterialsEvidenceItems = [
        "Story books",
        "Reference books",
        "Puzzles and games",
        "Textbooks",
        "Workbooks",
        "ICT equipment",
        "Writing Materials e.g. pens, pencils",
        "Art and craft materials",
        "Musical instruments"
    ];


    return (
        <div className="max-w-4xl mx-auto md:p-6 bg-white shadow-md rounded-lg">
            <div className="grid justify-center items-center ">
                <p className='font-semibold text-gray-700 text-center'>
                    Kindly ensure that all the information provided is accurate and true.
                </p>
                <p className='text-red-500 text-center'>
                    Note that all fields with asterisk (*) must be completed
                </p>
            </div>

            <form className="max-w-3xl mx-auto md:p-6 bg-white" onSubmit={handleSubmit}>
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">About your School</legend>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                        {renderInputField("School Name *", "text", "school_names", "Enter School Name", true)}
                        {renderInputField("Previous School Name (If Any)", "text", "prev_school_names", "Enter Previous School Name")}
                        {renderInputField("School Address *", "text", "school_address", "Enter School Address", true)}
                        {renderInputField("Nearest Landmark", "text", "nearest_landmark", "Enter Nearest Landmark")}
                        {renderSelectField("LGA *", 'lga', ["Ado", "Agatu", "Apa", "Buruku", "Gboko", "Guma", "Gwer East", "Gwer West", "Katsina-Ala", "Konshisha", "Kwande", "Logo", "Makurdi", "Obi", "Ogbadibo", "Ohimini", "Oju", "Okpokwu", "Otukpo", "Tarka", "Ukum", "Ushongo", "Vandeikya"]
                            , true)}
                        {renderInputField("School Email Address *", "email", "school_email", "Enter Email Address", true)}
                        {renderInputField("Proprietor's Name", "text", "proprietors_name", "Enter Proprietor's Name")}
                        {renderInputField("School Phone Numbers *", "text", "school_phone_nos", "Enter Phone Numbers", true)}
                        {renderInputField("Year Of Establishment", "number", "year_of_establishment", "Enter Year")}
                    </div>
                </fieldset>

                {/* Number of Teachers including Head Teacher  */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">Number of Teachers including Head Teacher</legend>
                    <div className="grid grid-cols-3 gap-4">
                        {renderInputField("Male", "number", "no_teachers_male", "Enter Number")}
                        {renderInputField("Female", "number", "no_teachers_female", "Enter Number")}
                        {renderInputField("PWD", "number", "no_teachers_pwd", "Enter Number")}
                    </div>
                </fieldset>

                {/* Teachers' Qualifications */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">Teachers' Qualifications</legend>
                    <div className="grid grid-cols-3 md:grid-cols-7 gap-4">
                        {renderInputField("SSCE", "number", "no_qualified_teachers_ssce", "Enter Number")}
                        {renderInputField("NCE", "number", "no_qualified_teachers_nce", "Enter Number")}
                        {renderInputField("Diploma", "number", "no_qualified_teachers_diploma", "Enter Number")}
                        {renderInputField("HND", "number", "no_qualified_teachers_hnd", "Enter Number")}
                        {renderInputField("Degree", "number", "no_qualified_teachers_degree", "Enter Number")}
                        {renderInputField("Masters", "number", "no_qualified_teachers_masters", "Enter Number")}
                        {renderInputField("PhD", "number", "no_qualified_teachers_phd", "Enter Number")}
                    </div>
                </fieldset>

                {/* Number of Teachers with skills for PWD */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">Number of Teachers with skills for PWD</legend>
                    <div className="grid grid-cols-3 gap-4">
                        {renderInputField("Male", "number", "no_teachers_with_pwd_skill_male", "Enter Number")}
                        {renderInputField("Female", "number", "no_teachers_with_pwd_skill_female", "Enter Number")}
                        {renderInputField("PWD", "number", "no_teachers_with_pwd_skill_pwd", "Enter Number")}
                    </div>
                </fieldset>

                {/* School Infrastructure */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg'>
                    <legend className="text-xl font-bold  uppercase">School Infrastructure</legend>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                        {renderInputField("No. Of Useable Classroom", "number", "no_usable_classrooms", "Enter Number")}
                        {renderInputField("No. Of Stream (arm)", "number", "no_streams", "Enter Number")}
                        {renderRadioGroupTrueFalse("Is Multigrade Teaching practiced?", "multigrade_teaching", ["Yes", "No"])}
                        {renderRadioGroupTrueFalse("Fencing?", "fencing", ["Yes", "No"])}
                    </div>

                    <div className=" mb-4">
                        <legend className="font-semibold mb-2 text-gray-700">No. of Functional Toilet(s) (Learners)</legend>
                        <div className='grid grid-cols-3 gap-4'>
                            {renderInputField("Male", "number", "learners_functional_toilets_male", "Enter Number")}
                            {renderInputField("Female", "number", "learners_functional_toilets_female", "Enter Number")}
                            {renderInputField("PWD", "number", "learners_functional_toilets_pwd", "Enter Number")}
                        </div>
                    </div>

                    <div className=" mb-4">
                        <legend className="font-semibold mb-2 text-gray-700">No. of Functional Toilet(s) (Staff)</legend>
                        <div className='grid grid-cols-3 gap-4'>
                            {renderInputField("Male", "number", "staff_functional_toilets_male", "Enter Number")}
                            {renderInputField("Female", "number", "staff_functional_toilets_female", "Enter Number")}
                            {renderInputField("PWD", "number", "staff_functional_toilets_pwd", "Enter Number")}
                        </div>
                    </div>

                    <div className='grid md:grid-flow-col'>
                        {renderCheckboxGroup("Available sources of water", "waterSources", ["Pipe borne water", "Borehole", "Well", "No source of water"])}
                        {renderCheckboxGroup("Source(s) of Electricity", "electricitySources", ["PHCN", "Solar", "Generator", "No source of electricity"])}
                    </div>
                </fieldset>
                <p className='text-center font-bold'>1</p>
                {/*  */}



                <fieldset className="border border-gray-300 p-4 shadow-md rounded-lg">
                    <legend className="text-xl font-bold mb-2 uppercase">School's Approval Status (Private School Only)</legend>
                    <p className="font-semibold mb-2">Tick the box which matches your status and attach evidence</p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                        <CheckboxWithFileInput id="guidline_purchased" label="Guidelines purchased" formData={formData} setFormData={setFormData} />
                        <CheckboxWithFileInput id="name_search" label="Name search" formData={formData} setFormData={setFormData} />
                        <CheckboxWithFileInput id="formal_application" label="Formal application" formData={formData} setFormData={setFormData} />
                        <CheckboxWithFileInput id="site_inspection" label="Site inspection" formData={formData} setFormData={setFormData} />
                        <CheckboxWithFileInput id="take_off_permit" label="Take-off permit" formData={formData} setFormData={setFormData} />
                        <CheckboxWithFileInput id="approval_inspection" label="Approval inspection" formData={formData} setFormData={setFormData} />
                        <CheckboxWithFileInput id="provisional_approval" label="Provisional approval" formData={formData} setFormData={setFormData} />
                        <CheckboxWithFileInput id="payment_accreditation_fee" label="Payment of accreditation fees/full inspection" formData={formData} setFormData={setFormData} />
                        <CheckboxWithFileInput id="full_approval" label="Full approval (after 10 years)" formData={formData} setFormData={setFormData} />
                    </div>
                </fieldset>

                <fieldset className="border border-gray-300 p-4 shadow-md rounded-lg">
                    <legend className="text-xl font-bold mb-2 uppercase">Annual Renewal Status (Private School Only)</legend>
                    <p className="font-semibold mb-2">Tick the box which matches your status and attach evidence</p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                        <CheckboxWithFileInput id="annual_renewal_year2021" label="annual Renewal Year 2021" formData={formData} setFormData={setFormData} />
                        <CheckboxWithFileInput id="annual_renewal_year2022" label="annual Renewal Year 2022" formData={formData} setFormData={setFormData} />
                        <CheckboxWithFileInput id="annual_renewal_year2023" label="annual Renewal Year 2023" formData={formData} setFormData={setFormData} />
                    </div>
                    <div title="School Ownership">
                        <p className="font-semibold mb-2">Tick the box which matches your status and attach evidence</p>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                            <CheckboxWithFileInput id="school_ownership_public" label="Public" formData={formData} setFormData={setFormData} />
                            <CheckboxWithFileInput id="school_ownership_private" label="Private (If private, tick as appropriate below)" formData={formData} setFormData={setFormData} />
                            <CheckboxWithFileInput id="school_ownership_single_private_owner" label="Single private owner" formData={formData} setFormData={setFormData} />
                            <CheckboxWithFileInput id="school_ownership_two_or_more_private_owners" label="Two or more private owners" formData={formData} setFormData={setFormData} />
                            <CheckboxWithFileInput id="school_ownership_religious_foundation" label="Religious foundation" formData={formData} setFormData={setFormData} />
                            <CheckboxWithFileInput id="school_ownership_non_religious_foundation" label="Non-religious foundation (e.g. Embassy, Commercial Company, Rochas Okorocha Foundation, Alhaji Ahmadu Chanchangi Foundation, etc)" formData={formData} setFormData={setFormData} />
                            <CheckboxWithFileInput id="school_ownership_others" label="Others" formData={formData} setFormData={setFormData} />
                        </div>
                    </div>
                </fieldset>

                <p className='text-center font-bold'>2</p>

                {/* How many learners do you have in nursery and primary classes? */}
                <fieldset className="border border-gray-300 p-4 shadow-md rounded-lg">
                    <legend className="text-xl font-bold mb-2 uppercase">Enrollment</legend>

                    <p className='font-semibold mb-2'>How many learners do you have in nursery and primary classes?</p>
                    <p className='text-sm text-gray-600 mb-4'>ECCDE includes any group below lower basic including pre-primary, pre-school, creche, kindergarten, play group, day care etc.</p>
                    <div className="mb-2 font-medium text-gray-700 grid grid-cols-4 gap-2">
                        <span></span>
                        <span>Boys</span>
                        <span>Girls</span>
                        <span>Total</span>
                    </div>


                    <EnrollmentRow label="ECCDE" id="eccde" formData={formData} handleInputChange={handleInputChangeEnrollmentRow} />
                    <EnrollmentRow label="Lower Basic" id="lower_basic" formData={formData} handleInputChange={handleInputChangeEnrollmentRow} />
                    <EnrollmentRow label="Middle Basic" id="middle_basic" formData={formData} handleInputChange={handleInputChangeEnrollmentRow} />
                    <EnrollmentRow label="Upper Basic" id="upper_basic" formData={formData} handleInputChange={handleInputChangeEnrollmentRow} />
                    <EnrollmentRow label="Post Basic" id="post_basic" formData={formData} handleInputChange={handleInputChangeEnrollmentRow} />


                </fieldset>

                <TuitionFeesForm formData={formData} handleInputChange={handleInputChange} />

                <AssociationsForm formData={formData} setFormData={setFormData} />

                <p className='text-center font-bold'>3</p>

                {/*School Management   */}

                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">School Management</legend>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">1.   School Administration</h3>
                        <p className="mb-2">
                            A well-managed school has a strong administrative structure and associated systems. In this section, we look at how your school is managed through delegation of duties.
                        </p>
                        <p className="mb-4">
                            Effective management involves getting things done through and with other people. One way of doing this is by delegating tasks to others. When duties are delegated to others and there is shared responsibility, much more can be done and can be done better. Please read the table below and use it to score your school. Enter the grade that best fits your school in the box provided.
                        </p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr className=''>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">In our school, it is the Head Teacher or Proprietor who deals with most issues in the school. When they need to, they instruct other members of staff to do something for them. We have no written roles and responsibilities for posts in the school.</td>
                                    <td className="border border-gray-300 p-2">Although our Head Teacher / Proprietor takes responsibility for handling most issues in the school, there are some teachers who have regular responsibilities assigned to them outside their normal teaching duties.</td>
                                    <td className="border border-gray-300 p-2">We have an organisational structure that is clearly defined and communicated to all staff. We also have written roles and responsibilities for all members of staff. We hold regular management meetings where tasks are assigned and reported back on. In the absence of the head teacher / proprietor decisions are still made and action is taken where necessary.</td>
                                    <td className="border border-gray-300 p-2">All of “B” plus the following: Before any member of staff is given additional responsibilities, discussions are held on the objectives of these tasks and who should have the responsibility for carrying them out, monitoring them and supporting them. We review our organisational structure and systems periodically to ensure it is aimed at school improvement. Where appropriate, handover notes are prepared.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_admin_score", ["A", "B", "C", "D"])}
                        </div>
                        <div>
                            {renderOrderedList("Examples of documentary evidence:", schoolManagementEvidenceItems)}
                        </div>
                    </div>
                </fieldset>

                <p className='text-center font-bold'>4</p>
                {/*  Professional Leadership Roles */}

                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">2.   Professional Leadership Roles</legend>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">a)   System for Lesson Observations</h3>
                        <p className="mb-2">
                            Lesson observation is when someone (the Head Teacher or another senior member of the academic staff) goes to a classroom and watches a teacher teaching a lesson. The lesson is watched from beginning to end and the teacher is not interrupted. The purpose of the lesson observation is to develop the teacher, not to supervise or correct him/her during the lesson.
                        </p>
                        <p className="mb-4">
                            Read the flow chart below to decide which of the grades matches your school. This is a sequential system – for example, your school must do all of the items listed in D and C before you can be a C. Enter your final grade in the box provided.
                        </p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">Teachers are observed in the class teaching. Teachers are given verbal feedback after the lesson. All teachers are observed at least once a term.</td>
                                    <td className="border border-gray-300 p-2">Both the conditions above are met AND Teachers are informed before they are observed. Teachers are clear about the purpose of the observation. All teachers are observed at least once a term.</td>
                                    <td className="border border-gray-300 p-2">All of the conditions above are met AND There are guidelines for the observer. A written report of the observation is kept in a file. The feedback given includes areas where improvement is expected and areas where good practice was observed. All teachers are observed at least once a term.</td>
                                    <td className="border border-gray-300 p-2">All of the conditions above are met AND Development needs are identified from lesson observations. Teachers attend training or are mentored based on needs identified during lesson observations. Each teacher has a file that clearly documents their level of improvement. All teachers are observed at least 3 times a term.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_leadership_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", lessonObservationEvidenceItems)}
                    </div>
                </fieldset>
                <p className='text-center font-bold'>5</p>

                {/*  Internal Peer Support and Mentoring Systems */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">b) Internal Peer Support and Mentoring Systems</legend>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Internal peer support and mentoring systems for teachers</h3>
                        <p className="mb-2">
                            Once you know the needs of your teachers, you can then look for ways to develop them. The most effective way to do this is through internal support and mentoring by the senior staff or by more experienced teachers (peers). A Professional development meeting is a meeting involving the Head Teacher and all of the teachers in a school. Issues that affect teaching and learning are discussed in the meeting. Some form of training is also given to staff during these meetings.
                        </p>
                        <p className="mb-4">
                            Please read the table below and use it to score your school. Enter the grade that best fits your school in the box provided.
                        </p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">Our Head Teacher tells our teachers where they need to improve in their teaching. No development meetings are held in our school.</td>
                                    <td className="border border-gray-300 p-2">Professional development meetings are held less than once a term during which we organise in-house seminars for teachers on general topics. Our teachers are encouraged to talk to one another about problems they have in the classroom.</td>
                                    <td className="border border-gray-300 p-2">At least 1 professional development meeting is held per term. More experienced teachers are assigned to support the newer ones.</td>
                                    <td className="border border-gray-300 p-2">At least 3 professional development meetings are held per term. The content of the training is decided by an analysis of the needs of the teachers. We also have peer support and mentoring systems for our teachers to support one another in their development. Our teachers have formal discussions about their professional development at least once a term.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_mentoring_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", internalSupportEvidenceItems)}
                    </div>
                </fieldset>

                {/*Meeting Development Needs of Teachers Externally  */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">c) Meeting Development Needs of Teachers Externally</legend>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Meeting the development needs of teachers externally</h3>
                        <p className="mb-4">
                            This section assesses the school's efforts in sending teachers to external training courses based on identified needs. Please read the table below and use it to score your school. Enter the grade that best fits your school in the box provided.
                        </p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">Our school does not pay for teachers to go on external training courses.</td>
                                    <td className="border border-gray-300 p-2">Our teachers are permitted to attend part-time training courses, but the school does not contribute financially.</td>
                                    <td className="border border-gray-300 p-2">We pay for our teachers to go on training courses outside the school based on request.</td>
                                    <td className="border border-gray-300 p-2">If there is a new area for our teachers to be developed on such as new curriculum content or ICT, we send our staff for external training. The training need will have been identified during the lesson observation or professional development meeting.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_teachers_development_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", externalTrainingEvidenceItems)}
                    </div>
                </fieldset>

                <p className='text-center font-bold'>6</p>

                {/* School Governance */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">School Governance</legend>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">3.   School governance function</h3>
                        <p className="mb-2">
                            A school is governed when an independent, objective and supportive body oversees what the school management does. This can be done by, for example, a Board of Governors or by a School Based Management Committee, but not by a Parent Teachers Association, since the PTA are not independent. The body oversees major decisions about the future of the school and gives guidance and advice to the school management.
                        </p>
                        <p className="mb-4">
                            Read the tables below and choose the grade which is most appropriate to your school.
                        </p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">The school proprietor makes all decisions about the school.</td>
                                    <td className="border border-gray-300 p-2">The school proprietor consults with an independent body (not the PTA), but final decisions are made by the proprietor alone.</td>
                                    <td className="border border-gray-300 p-2">There is an independent body which governs our school (not the PTA). It has clearly documented roles and responsibilities. The school proprietor consults with the body, but final decisions are made by the proprietor alone.</td>
                                    <td className="border border-gray-300 p-2">There is an independent body which governs our school (not the PTA). It has clearly documented roles and responsibilities. The body approves any major decision about the future of the school.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_governance_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", schoolGovernanceEvidenceItems)}
                    </div>

                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">4.   Feedback mechanisms between school and parents</h3>
                        <p className="mb-4">
                            This section assesses the feedback mechanisms between the school and parents. Please read the table below and use it to score your school. Enter the grade that best fits your school in the box provided.
                        </p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">The child’s report card is the only formal feedback given to parents on their children’s performance.</td>
                                    <td className="border border-gray-300 p-2">Parents give feedback to the school management at the PTA meetings. Parents come and get formal feedback on their children’s performance once a term in addition to the report card.</td>
                                    <td className="border border-gray-300 p-2">Parents meet with the school at least once a term and some joint decisions are taken. Regular feedback is given to parents on their children’s performance in addition to the report card. Parents can come at any time to raise complaints. Our PTA has clearly documented roles and responsibilities.</td>
                                    <td className="border border-gray-300 p-2">Our school takes account of the views of parents in the decision‐making process. We have links with our parents, keeping them well informed about their children’s academic and personal development in addition to the report card. We have formal procedures in place for dealing with parents’ complaints. Our PTA has clearly documented roles and responsibilities.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_mechanisms_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", feedbackMechanismEvidenceItems)}
                    </div>
                    <p className='text-center font-bold'>7</p>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">5.   Learner participation in school governance</h3>
                        <p className="mb-4">
                            This section assesses the level of learner participation in school governance. Please read the table below and use it to score your school. Enter the grade that best fits your school in the box provided.
                        </p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">Learners do not participate in the running of the school.</td>
                                    <td className="border border-gray-300 p-2">The school prefects meet with the school management to discuss issues.</td>
                                    <td className="border border-gray-300 p-2">There is a student council which discusses issues and makes their views known to the school management. Learners are encouraged to make suggestions about improvements in the school.</td>
                                    <td className="border border-gray-300 p-2">Learners have a safe space* to discuss issues that they feel are important to their wellbeing and to the development of their learning. There is a safe feedback mechanism from the learners to the school and vice versa. Learners take part in school decision making.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_learners_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", learnerParticipationEvidenceItems)}
                    </div>
                </fieldset>

                <p className='text-center font-bold'>8</p>
                {/* Community Relations */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">Community Relations</legend>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">6.   Relationship with the community</h3>
                        <p className="mb-2">
                            The relationship that a school has with its host community can be of great benefit to both the school and the community. A strong relationship built on trust can be observed through the use of the local language and culture within the school during Co-curricular activities and also through signs that the school plays a role in developing the host community.
                        </p>
                        <p className="mb-4">
                            Read the table below and choose the grade which is most appropriate to your school.
                        </p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">Our school does not have a relationship with the host community. Our school uses the local language and customs in our Co-curricular activities.</td>
                                    <td className="border border-gray-300 p-2">Our school has a cordial relationship with the host community and relates with them on a variety of issues. We attend community forums when appropriate. Our school uses the local language and customs in our Co-curricular activities.</td>
                                    <td className="border border-gray-300 p-2">We relate with our host community on a variety of issues and have a strong relationship with them. We take steps to ensure that we have no negative impact environmentally by disposing of our waste and channelling our drainage effectively. Our school takes part with the community in advocating for better services from government. We help learners cross the road near our school.</td>
                                    <td className="border border-gray-300 p-2">Our school has representation of the community leader on our governing body. We carry out specific activities which are aimed at developing the community. During our music and drama activities in the school we make use of the local culture, language and customs. We take steps to manage traffic during the rush hour and to ensure that we have no negative impact environmentally by disposing of our waste and channelling our drainage effectively. We share some of our resources with the community.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_community_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", communityRelationsEvidenceItems)}
                    </div>
                </fieldset>
                <p className='text-center font-bold'>9</p>
                {/* School Development */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">School Development</legend>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">7.   Planning for school development</h3>
                        <p className="mb-2">
                            For a school to develop it needs to be clear about where it wants to go and how it wants to get there. A long term plan is called a strategic plan. This sets out clear goals and objectives for the next 5 – 10 years. To ensure that the school can achieve the long term goals, a school also needs to develop annual plans, sometimes called school improvement plans or school development plans. Before a school develops its annual plan, it should carry out a self-evaluation (such as this grading exercise) to know where the school currently is.
                        </p>
                        <p className="mb-4">
                            Please assess the planning cycle of your school by selecting the grade below that best fits what happens in your school. Remember you will have to provide documented evidence for your grade.
                        </p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">Our school has a vision. The proprietor knows what needs to be done each year, but is constrained by lack of funds. The school does not have a written plan, but when funds are available, the proprietor makes use of them to serve immediate needs.</td>
                                    <td className="border border-gray-300 p-2">Our school has a clear vision which is easily visible in the school and classrooms. The proprietor knows what needs to be done each year and discusses this with the PTA, who support wherever possible. There are minutes of meetings to show that discussions are held between the proprietors and the staff in the school to discuss the needs of the school, but this is not translated into a school development plan.</td>
                                    <td className="border border-gray-300 p-2">Our school has a clear vision that everyone in the school is aware of. Every year we analyse our pupils’ results. Every year we have a written plan for staff recruitment and training, infrastructure needs and other key activities. Some members of staff contribute to the process of drawing this plan up. Parents are aware of our needs and support the school where they can.</td>
                                    <td className="border border-gray-300 p-2">Everyone in our school knows the school vision and feels motivated to achieve it. Every year the school, parents and learners analyse the performance of the school including what we are doing well and what we need to strengthen. From this we jointly agree on which needs are most urgent, and together we prioritise the tasks required to meet these needs. This forms the basis of our plan for the year. At the end of the year, we come together to review what was achieved as a result of implementing the plan.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_development_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", schoolDevelopmentEvidenceItems)}
                    </div>
                </fieldset>
                <p className='text-center font-bold'>10</p>

                {/* Learner Welfare */}

                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">8. Learner Welfare</legend>

                    {/* Learner Health */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">a.   Learner Health</h3>
                        <p className="mb-2">Please read the table below and use it to score your school. Enter the grade that best fits your school in the box provided.</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">There is no first aid available in the school. Learners are often sent home when they get sick or injured.</td>
                                    <td className="border border-gray-300 p-2">There is first aid kit available in the school and learners are sometimes sent to the nearest health facility for more serious cases.</td>
                                    <td className="border border-gray-300 p-2">The school has resident health personnel for minor ailments and first aid. More serious cases are referred to the nearest health facility available.</td>
                                    <td className="border border-gray-300 p-2">The school has resident health personnel for minor ailments and first aid. The school is linked to a good quality health facility close to the school where more serious cases are referred. Some teachers are trained to provide first-aid.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_leaners_health_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", learnerHealthEvidenceItems)}
                    </div>

                    {/* Learner Hygiene */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">b.   Learner Hygiene</h3>
                        <p className="mb-2">Please read the table below and use it to score your school. Enter the grade that best fits your school in the box provided.</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">Learners are taught about personal hygiene. There is no source of water on the school premises.</td>
                                    <td className="border border-gray-300 p-2">Learners are taught about personal hygiene. Written signs about hygiene procedures such as hand washing and using the toilets are not widely displayed in the class and toilet areas. Well or tap water is available on the school premises.</td>
                                    <td className="border border-gray-300 p-2">All learners are taught about personal hygiene. Clear written signs about hygiene procedures such as hand washing and using the toilets are displayed in the class and toilet areas. Learners have easy access to clean running water.</td>
                                    <td className="border border-gray-300 p-2">There are clear guidelines for staff on maintaining learner hygiene in school and for outside school activities. All learners are taught about personal hygiene. Clear written signs about hygiene procedures such as hand washing and using the toilets are displayed in the class and toilet areas. Learners have easy access to clean running water.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_learner_hygiene_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", learnerHygieneEvidenceItems)}
                    </div>

                    {/* Mealtime for Learners */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">c.   Mealtime for Learners</h3>
                        <p className="mb-2">Please read the table below and use it to score your school. Enter the grade that best fits your school in the box provided.</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">No food is available within the school premises. Learners have to bring their own drinking water to school.</td>
                                    <td className="border border-gray-300 p-2">The school does not provide meals but learners are only allowed to buy food and drinking water within the school premises. The quality of food sold is assessed.</td>
                                    <td className="border border-gray-300 p-2">The school provides the same midday meals for learners but there is no consideration for allergies or health issues. The conditions for preparation and quality of food are assessed.</td>
                                    <td className="border border-gray-300 p-2">The school provides midday meals and the menu also caters for learners with food allergies and health issues. The conditions for preparation and quality of food are assessed. Learners have access to clean drinking water in the school.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_learner_mealtime_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", learnerMealtimeEvidenceItems)}
                    </div>

                </fieldset>
                <p className='text-center font-bold'>11</p>
                {/* Learner Safety */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">9. Learner Safety</legend>

                    {/* Child Protection */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">a.   Child Protection</h3>
                        <p className="mb-2">Please read the table below and use it to score your school. Enter the grade that best fits your school in the box provided.</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">There is no policy or formal procedure for child protection in our school.</td>
                                    <td className="border border-gray-300 p-2">The school has a policy and procedures it follows for child protection.</td>
                                    <td className="border border-gray-300 p-2">The school has and implements a policy for child protection that is in line with the provisions of the Child’s Right Act. The policy sets out clear procedures to follow in dealing with issues of abuse and this is shared with parents, staff and learners.</td>
                                    <td className="border border-gray-300 p-2">The school has and implements a policy for child protection that is in line with the provisions of the Child’s Right Act. The policy sets out clear procedures to follow in dealing with issues of abuse and this is shared with parents, staff and learners for their input. Learners are well aware of their role in implementing this policy.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_child_protection_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", childProtectionEvidenceItems)}
                    </div>

                    {/* Security of Learning Environment */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">b.   Security of Learning Environment</h3>
                        <p className="mb-2">Please read the table below and use it to score your school. Enter the grade that best fits your school in the box provided.</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">The school premises are a thoroughfare and there are no doors to keep learners in and strangers out. None of the staff is trained in child safety and security. Most learners in the early years often go home unaccompanied by adults.</td>
                                    <td className="border border-gray-300 p-2">The school is within fenced premises but sometimes without designated security personnel. None of the staff is trained in child safety and security. Where transport is provided, learners are often crowded in the school bus.</td>
                                    <td className="border border-gray-300 p-2">The school is within a fenced and gated premises manned with security personnel. Some teachers are trained in child safety and security. Where the school provides transport, it is sufficient, monitored and the driver is certified by Lagos Drivers Institute.</td>
                                    <td className="border border-gray-300 p-2">The school monitors movement within and around the school premises. All teachers are regularly trained on learner safety and security. In addition to sufficient, monitored transport driven by a certified driver, the school confirms the identity of anyone sent to pick-up a child with the parents and must be notified in advance of any change.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_learners_security_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", securityEnvironmentEvidenceItems)}
                    </div>

                    {/* Supervision at Playtime */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">c.   Supervision at Playtime</h3>
                        <p className="mb-2">Please read the table below and use it to score your school. Enter the grade that best fits your school in the box provided.</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">Learners are not supervised when they are outside the classroom at playtime.</td>
                                    <td className="border border-gray-300 p-2">Older learners are not supervised at playtime.</td>
                                    <td className="border border-gray-300 p-2">Play is always supervised by a teacher or caregiver.</td>
                                    <td className="border border-gray-300 p-2">Play is always supervised by at least one teacher or caregiver to 35 learners.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_playtime_supervision_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", playtimeSupervisionEvidenceItems)}
                    </div>

                </fieldset>
                <p className='text-center font-bold'>12</p>

                {/*  Promoting Girl-Child Education*/}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">10.    Promoting Girl-Child Education</legend>

                    {/* Promoting Girl-Child Education */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Promoting Girl-Child Education</h3>
                        <p className="mb-2">Please read the table below and use it to score your school. Enter the grade that best fits your school in the box provided.</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">Girls do not have a separate toilet. We adhere strictly to the content and timing stipulated in the scheme of work with regards to teaching girls about hygiene and their body. We respond to issues on sexual harassment or abuse when they are identified but do not have defined guidelines we follow.</td>
                                    <td className="border border-gray-300 p-2">The girls have a separate toilet from the boys and when it is needed we provide sanitary items. Even when it is not included in the scheme of work, teachers are required to teach lessons on girls’ hygiene and their body. We respond to issues on sexual harassment or abuse when they are identified but do not have defined guidelines we follow.</td>
                                    <td className="border border-gray-300 p-2">We have separate toilets for the girls at a maximum ratio of 35 girls to 1 female toilet. We have a store of sanitary pads but they are not kept in the toilet. In addition to what is in the scheme of work, we hold a common session with male and female learners to teach them about their body and how to keep it safe and hygienic. Our school has and implements clearly defined guidelines against sexual harassment or abuse.</td>
                                    <td className="border border-gray-300 p-2">The girls have separate toilets at a maximum ratio of 35 girls to 1 female toilet. We always provide sanitary pads in the toilets. In addition to what is in the scheme of work, we hold special sessions with girls at least once in a term to teach them about their body and how to keep it safe and hygienic. Our school has and implements clearly defined guidelines against sexual harassment or abuse and this is shared with all learners, staff and parents.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_promiting_girl_child_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", girlChildEducationEvidenceItems)}
                    </div>
                </fieldset>
                <p className='text-center font-bold'>13</p>

                {/* Learner Behaviour and Personal Development */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">Learner Behaviour and Personal Development</legend>

                    {/* Pastoral Care */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">11.  Pastoral Care</h3>
                        <p className="mb-2">Pastoral Care is the practice of looking after the learners’ personal and social wellbeing under the care of a teacher including health, social and moral education, behaviour management and emotional support.</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">Teachers only know a few learners well. The school is not equipped to provide care with consideration of the individual learners and their backgrounds. Our school does not take action to support pupils with particular problems in their learning and development.</td>
                                    <td className="border border-gray-300 p-2">Teachers only know some of their learners well. The care provided by the school does not always show understanding of the learners and their backgrounds. The school does not take enough action to support pupils with particular problems in their learning and development.</td>
                                    <td className="border border-gray-300 p-2">Teachers know their learners fairly well and some are skilled at helping learners take advantage of the opportunities provided by the school. The relationship between staff and learners is good and wholesome. Some emphasis is placed on supporting those with problems of attitude or behaviour.</td>
                                    <td className="border border-gray-300 p-2">Teachers know all their learners extremely well and are skilled at helping them take advantage of the opportunities provided by the school. The school provides opportunity for excellent relationships between staff and learners, and staff are devoted to helping learners overcome problems whether in their work or their relationships with others.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_lpastoral_care_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", pastoralCareEvidenceItems)}
                    </div>
                </fieldset>

                {/* Reward and Sanctions */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">12.    Reward and Sanctions</legend>

                    {/* Rewards and Sanctions */}
                    <div className="mb-4">
                        <p className="mb-2">The use of rewards and sanctions helps to reinforce positive behaviour and discourage breach of rules by learners. They should be constructive, and not breed resentment.</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">The school does not have a rewards and sanctions policy. Learners are more often sanctioned for misconduct than rewarded for positive behaviour. There is no record of rewards and sanctions.</td>
                                    <td className="border border-gray-300 p-2">The school does not have a rewards and sanctions policy. Rewards and sanctions are not consistently administered across learners. The record of rewards and sanctions is not always updated or reviewed.</td>
                                    <td className="border border-gray-300 p-2">The school has a rewards and sanctions policy which is fair and transparent. It keeps an up-to-date record of rewards and sanctions but this is not always reviewed to ensure rewards and sanctions are appropriate to the age of the learner and constructive.</td>
                                    <td className="border border-gray-300 p-2">The school has a rewards and sanctions policy which is fair, transparent, consistently administered and effective. Each class has an up-to-date record of rewards and sanctions which is reviewed to ensure rewards and sanctions are appropriate to the age of the learner and constructive.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_reward_sanction_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", rewardsSanctionsEvidenceItems)}
                    </div>
                </fieldset>
                <p className='text-center font-bold'>14</p>

                {/* Promoting Learning Time */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">13.    Promoting Learning Time</legend>

                    {/* Learner Attendance */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">a.   Learner Attendance</h3>
                        <p className="mb-2">Learners can only learn if they are in the classroom and if the teacher is in the classroom and if the lessons begin and end when they are supposed to. This question looks at what your school is doing to ensure that these three things are happening.</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">Our school does not record attendance.</td>
                                    <td className="border border-gray-300 p-2">Some classes have attendance registers which are marked and checked.</td>
                                    <td className="border border-gray-300 p-2">All classes have attendance registers which are marked daily, checked weekly and long term absences are followed up.</td>
                                    <td className="border border-gray-300 p-2">All classes have attendance registers which are marked and checked daily. Absences are followed up daily with calls / texts. Our school identifies patterns of absenteeism and we use our data to set and monitor attendance targets for all learners.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_learner_attendance_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", learnerAttendanceEvidenceItems)}
                    </div>

                    {/* Teacher Attendance */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">b.   Teacher Attendance</h3>
                        <p className="mb-2">This section looks at the school's measures for ensuring teacher attendance and punctuality.</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">There is no record of teacher attendance or punctuality.</td>
                                    <td className="border border-gray-300 p-2">Teachers fill in a time book and movement book.</td>
                                    <td className="border border-gray-300 p-2">Teachers fill in a time book and movement book and they are checked on a regular basis.</td>
                                    <td className="border border-gray-300 p-2">Teachers fill in a time book and movement book, which are checked. There are consequences for absenteeism or lateness.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_teachers_attendance_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", teacherAttendanceEvidenceItems)}
                    </div>

                    {/* Lesson Times */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">c.   Lesson Times</h3>
                        <p className="mb-2">This section assesses how well the school adheres to lesson times and ensures lessons begin and end as scheduled.</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">There are no timetables in the school.</td>
                                    <td className="border border-gray-300 p-2">There are copies of timetable pasted in most classrooms.</td>
                                    <td className="border border-gray-300 p-2">Every class has a current timetable on the wall. Classrooms are checked to ensure teachers follow the timetable.</td>
                                    <td className="border border-gray-300 p-2">Every class has a current timetable on the wall. Classrooms are not checked as we have other means of assessing learning.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_lesson_times_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", lessonTimesEvidenceItems)}
                    </div>
                </fieldset>
                <p className='text-center font-bold'>15</p>

                {/* Continuous Assessment  */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">14.    Continuous Assessment</legend>

                    {/* Continuous Assessment */}
                    <div className="mb-4">
                        <p className="mb-2">Continuous assessment and feedback are used to support learning and to help pupils know how to improve their work.</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">Learners are assessed using exams and sometimes tests to only determine ability to progress to the next class.</td>
                                    <td className="border border-gray-300 p-2">Learners are regularly assessed using tests and exams to only determine ability to progress to the next class.</td>
                                    <td className="border border-gray-300 p-2">Learners’ assessment is frequent. Learners are assessed using a range of assessment techniques and understand what is required to make progress.</td>
                                    <td className="border border-gray-300 p-2">Learners’ assessment is continuous and involves a variety of methods such as observation, group work and self-assessment to assess a wide range of attainment. Learners are involved in setting their own targets and understand what is required to achieve them.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_continuous_assessment_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", continuousAssessmentEvidenceItems)}
                    </div>

                    {/* Co-curricular Activities */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">15.  Co-curricular Activities</h3>
                        <p className="mb-2">Co-curricular activities including school clubs, sporting, musical and dramatic areas of interest are to enrich the curriculum and promote learners’ personal qualities and levels of confidence.</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">Learners only participate in physical outdoor sports at least once a week. This is not always supervised.</td>
                                    <td className="border border-gray-300 p-2">Learners participate in outdoor sports and competitions with similar schools in the neighbourhood. They sometimes go on local school trips. There are a few school clubs, but learners don’t have to participate in these.</td>
                                    <td className="border border-gray-300 p-2">There is a range of Co-curricular activities to choose from which involve most learners and most of which are supervised by teachers. Learners are also given the opportunity to take part in field trips and participate in competitions.</td>
                                    <td className="border border-gray-300 p-2">There is a wide range of Co-curricular activities to choose from which involve all learners and which are supervised by teachers. Learners are also given the opportunity to take part in field trips and participate in competitions.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_curricular_activities_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", cocurricularActivitiesEvidenceItems)}
                    </div>
                </fieldset>

                <p className='text-center font-bold'>16</p>

                {/*School Building and Premises  */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">School Building and Premises</legend>

                    {/* School Environment Safety */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">16.    Quality of the school buildings and premises</h3>
                        <p className="mb-2">The provision of infrastructure in a school supports a child’s learning. A school should make sure that the infrastructure provided is safe, well maintained, adequate, suitable and used properly for the size and type of school it serves.  For each of the questions in this section, please read the tables provided and use them to score your school.  Enter the grade that best fits your school in the box provided.</p>
                        <p className='mb-2'>a.    Is the school environment safe for learners?</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">There are no fire buckets or functional fire extinguishers in the school. Electrical wires and equipment are not regularly checked. There are naked wires in the school. The school premises are bushy. There is debris or rubbish in areas used by learners. Some of the buildings are unstable in one way or another.</td>
                                    <td className="border border-gray-300 p-2">The school has fire buckets placed appropriately around the school premises. Electrical faults are not always repaired in a timely fashion. The compound is generally neat, but there are areas used by learners where debris can be found.</td>
                                    <td className="border border-gray-300 p-2">There are functional fire extinguishers on every floor of the school building. Some members of staff have been trained in fire safety. The compound is neat and tidy and any electrical fault is quickly reported and repaired.</td>
                                    <td className="border border-gray-300 p-2">There are functional fire extinguishers on every floor of the school building, which are checked periodically. There are dedicated fire wardens and fire drills are held regularly. The school building and premises are free of debris, naked wires and winding cables. Hazards such as sharp objects are checked for and removed.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_learners_safety_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", schoolEnvironmentSafetyEvidenceItems)}
                    </div>

                    {/* School Building Maintenance */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">b.   Are the school buildings and premises regularly maintained?</h3>
                        <p className="mb-2">This section assesses the school's maintenance of buildings and premises to ensure they are structurally sound and safe for learners.</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">Our buildings are still under construction, with floors and walls that have not been plastered and a lack of ceilings. When it rains our roof leaks and rain comes in through the windows. We have used temporary materials to renovate our buildings.</td>
                                    <td className="border border-gray-300 p-2">Most of our buildings are solid structures. Some of our classrooms have leaks, uneven floors, cracked walls, broken doors and windows and broken furniture.</td>
                                    <td className="border border-gray-300 p-2">Our buildings are all structurally sound. Leaking roofs, uneven floors, cracked walls and broken doors and windows are repaired where possible. Most of our furniture is in good condition. We believe in regular maintenance, but sometimes due to resource constraints, work is not done when it should be.</td>
                                    <td className="border border-gray-300 p-2">Our buildings are all structurally sound. No roof leaks, no floor is uneven, no wall is cracked, and every window and door is in place and is in full working order. All our furniture is in good condition. All of our buildings are painted regularly. Any maintenance work needed is identified and resolved immediately. We have a maintenance plan.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_building_maintenance_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", schoolBuildingMaintenanceEvidenceItems)}
                    </div>
                </fieldset>
                <p className='text-center font-bold'>17</p>

                {/* School Building and Premises */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>

                    {/* School Environment Safety */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">c.  Are the school buildings and premises adequate for the existing learners?</h3>
                        <p className="mb-2">(i)    Are learners from different classes taught in the same room</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">There are no fire buckets or functional fire extinguishers in the school. Electrical wires and equipment are not regularly checked. There are naked wires in the school. The school premises are bushy. There is debris or rubbish in areas used by learners. Some of the buildings are unstable in one way or another.</td>
                                    <td className="border border-gray-300 p-2">The school has fire buckets placed appropriately around the school premises. Electrical faults are not always repaired in a timely fashion. The compound is generally neat, but there are areas used by learners where debris can be found.</td>
                                    <td className="border border-gray-300 p-2">There are functional fire extinguishers on every floor of the school building. Some members of staff have been trained in fire safety. The compound is neat and tidy and any electrical fault is quickly reported and repaired.</td>
                                    <td className="border border-gray-300 p-2">There are functional fire extinguishers on every floor of the school building, which are checked periodically. There are dedicated fire wardens and fire drills are held regularly. The school building and premises are free of debris, naked wires and winding cables. Hazards such as sharp objects are checked for and removed.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_classes_taught_in_same_room_score", ["A", "B", "C", "D"])}
                        </div>
                    </div>

                    {/* School Environment Safety */}
                    <div className="mb-4">
                        <p className="mb-2">(ii)  Is there sufficient space in the classrooms for teaching and learning?</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">The learners’ chairs and tables are very close together and the teacher cannot move freely to each child.  There is no open space available in the classroom.  No teaching and learning resources are stored in the classrooms.</td>
                                    <td className="border border-gray-300 p-2">In 80% of classrooms, the teacher can reach all learners, but the space available is not sufficient for all learners to join in and move around at the same time.  We have no storage facilities in our classrooms. </td>
                                    <td className="border border-gray-300 p-2">In 80% of classrooms, the teacher can reach all learners and there is enough room for different seating arrangements.  There is space available for all to move around.   We have some storage facilities in the classrooms, but also use a common store to keep some teaching and learning resources.</td>
                                    <td className="border border-gray-300 p-2">In every classroom, the teacher can easily reach every learner and there is enough space in the room for different seating arrangements.  There is space available for all learners to move around at the same time.  We also have storage space for teaching and learning resources which are easily accessible by the teachers and learners.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_space_in_classroom_score", ["A", "B", "C", "D"])}
                        </div>
                    </div>

                    {/* School Building Maintenance */}
                    <div className="mb-4">
                        <p className="mb-2">(iii)   Are there sufficient toilets in the school for teachers and learners?</p>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">Our school has no toilets.</td>
                                    <td className="border border-gray-300 p-2">Our learner to toilet ratio exceeds 35:1.  Our school does not have a separate staff toilet.</td>
                                    <td className="border border-gray-300 p-2">Our school has one staff toilet and the learner to toilet ratio does not exceed 35:1.</td>
                                    <td className="border border-gray-300 p-2">Our teachers have separate toilets to our learners.  We have male and female teachers’ toilets.  The learners’ toilets are divided into male and female toilets.  The learner to toilet ratio does not exceed 35:1</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_sufficient_toilets_score", ["A", "B", "C", "D"])}
                        </div>
                    </div>
                </fieldset>
                <p className='text-center font-bold'>18</p>

                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>

                    {/* Premises Suitability for Current and Future Learners */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">d.   Are the school premises suitable for the current and future learners?</h3>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">D</th>
                                    <th className="border border-gray-300 p-2">C</th>
                                    <th className="border border-gray-300 p-2">B</th>
                                    <th className="border border-gray-300 p-2">A</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">Our school uses a rented building, which we have tried to customise, but which is not ideal. Our school does not own land. Our existing premises are insufficient for the current number of learners, and expansion would be difficult.</td>
                                    <td className="border border-gray-300 p-2">The buildings (including playground) on our current site were not originally designed to be used as a school. They have been customised to meet the needs of our current students and give suitable provision. For future additional learners to be accommodated, the school will have to move to bigger premises or be restructured.</td>
                                    <td className="border border-gray-300 p-2">Our buildings (including playground) are built following national building standards, are all structurally sound and are designed and built for a school. Our school has been built over time and is suitable for our current learners. There is pressure on our school to expand, but this will mean developing a new site, or restructuring the existing one.</td>
                                    <td className="border border-gray-300 p-2">Our school is on a permanent site which is owned by the school. The buildings and playground are in line with national building standards. Our school has a site plan which shows every building in existence or which will need to be built with time. There is adequate space on our current site for all future development needs.</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_premises_suitable_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", schoolBuildingsAdequacyEvidenceItems)}
                    </div>
                </fieldset>
                <p className='text-center font-bold'>19</p>

                {/*Use of The Curriculum  */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">Use of The Curriculum</legend>

                    {/* Curriculum Application */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">17.  How do your teachers apply the curriculum in their lessons?</h3>
                        <p className="mb-2">A structured curriculum planning process should include certain elements. Read the flow chart below to decide which of the grades matches your school. This is a sequential system – for example, your school must do all of the items listed in D and C before you can be a C. Enter your final grade in the box provided. Remember that you need to be able to show documented evidence that these exist.</p>

                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">Grade</th>
                                    <th className="border border-gray-300 p-2">Criteria</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">D</td>
                                    <td className="border border-gray-300 p-2">
                                        <ul className="list-disc list-inside">
                                            <li>There is a planning week before the start of each term</li>
                                            <li>Copies of the curriculum, approved schemes of work, etc are available for teachers</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 p-2">C</td>
                                    <td className="border border-gray-300 p-2">
                                        <ul className="list-disc list-inside">
                                            <li>Both the conditions above are met AND</li>
                                            <li>Plans are developed for each term, each week, and each lesson for all classes and subjects</li>
                                            <li>Someone in the school is responsible for checking the quality of the plans</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 p-2">B</td>
                                    <td className="border border-gray-300 p-2">
                                        <ul className="list-disc list-inside">
                                            <li>All of the conditions above are met AND</li>
                                            <li>Measures are taken to incorporate learners’ prior knowledge and progression from one class to the next into plans</li>
                                            <li>Learners’ notebooks are checked to ensure plans are being implemented</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 p-2">A</td>
                                    <td className="border border-gray-300 p-2">
                                        <ul className="list-disc list-inside">
                                            <li>All of the conditions above are met AND</li>
                                            <li>There is a continuous review of objectives against achievements</li>
                                            <li>The plans include provision for differentiation*</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="mb-2">*Differentiation is the process by which differences between learners are accommodated so that all learners in a group have the best possible chance of learning.</p>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_curriculum_planning_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", curriculumApplicationEvidenceItems)}
                    </div>
                </fieldset>
                <p className='text-center font-bold'>20</p>
                {/* Teaching and Learning Resources */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">Teaching and Learning Resources</legend>

                    {/* Sufficient Teachers */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">18.  Are there sufficient teachers in your school?</h3>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">Grade</th>
                                    <th className="border border-gray-300 p-2">Criteria</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">D</td>
                                    <td className="border border-gray-300 p-2">
                                        Some class teachers have responsibility for teaching more than one class at the same time. Some classes have more than 35 learners.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 p-2">C</td>
                                    <td className="border border-gray-300 p-2">
                                        Every class has a teacher. Most classes have 35 learners or less.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 p-2">B</td>
                                    <td className="border border-gray-300 p-2">
                                        Every class has a teacher and some have a teaching assistant. Every class has 35 learners or less.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 p-2">A</td>
                                    <td className="border border-gray-300 p-2">
                                        Every class has a teacher and a teaching assistant. Every class has 35 learners or less. In classes where there are learners with special needs, additional teaching support is provided.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_learning_resources_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", teachingResourcesEvidenceItems)}
                    </div>

                    {/* Classroom Environment */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">19.  Does the classroom environment enhance learning?</h3>

                        {/* Boards and Consumables */}
                        <div className="mb-4">
                            <h4 className="text-md font-semibold mb-2">a. Boards and Consumables</h4>
                            <table className="w-full mb-4 border-collapse border border-gray-300">
                                <thead>
                                    <tr>
                                        <th className="border border-gray-300 p-2">Grade</th>
                                        <th className="border border-gray-300 p-2">Criteria</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="border border-gray-300 p-2">D</td>
                                        <td className="border border-gray-300 p-2">
                                            We have black boards in all classrooms but they are not all fixed to the wall. Some class years share chalk boards.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 p-2">C</td>
                                        <td className="border border-gray-300 p-2">
                                            We have fixed black boards / white boards in every room.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 p-2">B</td>
                                        <td className="border border-gray-300 p-2">
                                            We have fixed black boards / white boards in every room. Projectors are available.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 p-2">A</td>
                                        <td className="border border-gray-300 p-2">
                                            All classrooms have white boards. Several classrooms have access to interactive white boards. Projectors are available.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="mb-4">
                                {renderRadioGroup("Our school scores grade", "school_consumables_score", ["A", "B", "C", "D"])}
                            </div>
                        </div>

                        {/* Classroom environment and ventilation */}
                        <div className="mb-4">
                            <h4 className="text-md font-semibold mb-2">b.   Classroom environment and ventilation</h4>
                            <table className="w-full mb-4 border-collapse border border-gray-300">
                                <thead>
                                    <tr>
                                        <th className="border border-gray-300 p-2">Grade</th>
                                        <th className="border border-gray-300 p-2">Criteria</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="border border-gray-300 p-2">D</td>
                                        <td className="border border-gray-300 p-2">
                                            Learners share seats and desks or sit on the floor. Our classrooms use natural light, which can be a challenge in certain types of weather conditions.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 p-2">C</td>
                                        <td className="border border-gray-300 p-2">
                                            Our classrooms are bright and all learners have a chair to sit on and a table to use.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 p-2">B</td>
                                        <td className="border border-gray-300 p-2">
                                            All of our classrooms are well lit and have fans or air conditioners. The chairs and tables, walls and displays all add colour and stimulation to our learners. The tables and chairs are designed to form different classroom arrangements.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 p-2">A</td>
                                        <td className="border border-gray-300 p-2">
                                            All of our classrooms are well lit and all have air conditioning. The chairs and tables, walls and displays all add colour and stimulation to our learners. The tables and chairs are designed to form different classroom arrangements. We have reading corners with a comfortable place to sit on the floor.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="mb-4">
                                {renderRadioGroup("Our school scores grade", "school_ventilation_score", ["A", "B", "C", "D"])}
                            </div>
                        </div>
                    </div>
                </fieldset>
                <p className='text-center font-bold'>21</p>

                {/* Instructional Materials */}
                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">20.    Instructional Materials</legend>

                    {/* Use of teaching materials to support learning */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">This question is to assess whether the quality, quantity and range of teaching and learning aids adequately support the curriculum offered by the school and stimulate learning.</h3>
                        <h3 className="text-lg font-semibold mb-2"><b>Instructional materials</b> include anything that helps a teacher teach.  They can range from a DVD, an internet site or computer game, flash-cards, to stones, bottle tops and sticks.  What is important is that the materials assist the teacher to achieve the objectives of the lesson. <b>Learning materials</b> are resources that learners use in their learning.  Examples of learning aids include: textbooks and workbooks; art and craft materials; games and puzzles</h3>
                        <h3 className="text-lg font-semibold mb-2">a.   Use of teaching materials to support learning</h3>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">Grade</th>
                                    <th className="border border-gray-300 p-2">Criteria</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">D</td>
                                    <td className="border border-gray-300 p-2">
                                        Teaching materials are not used. We don’t display work on the walls due to security and the type of walls.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 p-2">C</td>
                                    <td className="border border-gray-300 p-2">
                                        Most lessons are taught without the use of teaching materials or using only one type. Most classroom displays do not match topics being taught each week.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 p-2">B</td>
                                    <td className="border border-gray-300 p-2">
                                        Most teachers in our school use at least 2 different teaching materials in each lesson. There are displays on the walls of most classrooms that match the topics being taught each week.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 p-2">A</td>
                                    <td className="border border-gray-300 p-2">
                                        All teachers in our school use a wide range of teaching materials in each lesson. There are displays on the walls of all classrooms that match the topics being taught each week. Teaching materials are included in lesson planning.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_teaching_materials_score", ["D", "C", "B", "A"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", instructionalMaterialsEvidenceItems)}
                    </div>

                    {/* Availability of learning materials */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">b.   Availability of learning materials</h3>
                        <table className="w-full mb-4 border-collapse border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">Grade</th>
                                    <th className="border border-gray-300 p-2">Criteria</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 p-2">D</td>
                                    <td className="border border-gray-300 p-2">
                                        Most of our teachers have access to textbooks. Learners share textbooks in most subjects. Learners provide their own writing materials and these are often inadequate.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 p-2">C</td>
                                    <td className="border border-gray-300 p-2">
                                        Each learner has a textbook, workbook and other learning materials for core subjects. Learning materials are sufficient for most learners. Most teachers do not have access to DVDs or computers.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 p-2">B</td>
                                    <td className="border border-gray-300 p-2">
                                        Each learner has a variety of textbooks, workbooks and other learning materials for every subject. Learning materials are sufficient for each learner and are replaced as necessary. Learners only use DVDs when they are relevant to the lesson. Our learners use computers for subjects other than ICT.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 p-2">A</td>
                                    <td className="border border-gray-300 p-2">
                                        Our school assesses textbooks for suitability before approving them for use. Each learner has a variety of textbooks, workbooks and other learning materials for every subject. Learning materials are sufficient for each learner and are replaced as necessary. Learning materials are included in the lesson plans. Teachers are given resources to produce learning materials. Learners are given access to ICT equipment for independent research. Teachers have access to a range of ICT facilities such as the internet, DVDs and satellite television and educational computer software.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-4">
                            {renderRadioGroup("Our school scores grade", "school_learning_materials_score", ["A", "B", "C", "D"])}
                        </div>
                        {renderOrderedList("Examples of documentary evidence:", learningMaterialsEvidenceItems)}
                    </div>
                </fieldset>
                <p className='text-center font-bold'>22</p>

                <fieldset className='border border-gray-300 p-4 shadow-md rounded-lg mb-2'>
                    <legend className="text-xl font-bold mb-2 uppercase">Form Completion</legend>

                    <p className="mb-4">Thank you for taking the time to complete this self-assessment form.</p>
                    <p className="mb-4">Please ensure you entered this form for nursery and primary classes only. If not, please redo.</p>
                    <p className="mb-4">Please photocopy the completed form, so that you can retain a copy in your school.</p>
                    <p className="mb-4">This form should be returned to the Benue State Bureau for Education Quality Assurance before the deadline given.</p>
                    <p className="mb-4">To the best of our knowledge the information provided on this form is true and correct as of the date of the self-assessment.</p>


                    <legend className="text-xl font-bold mb-2 uppercase">Head Teacher</legend>

                    <table className="min-w-full divide-y divide-gray-200 mb-4 border border-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Details</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Input</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            <tr>
                                <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-700">Name</td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <input
                                        type="text"
                                        name="head_teacher_name"
                                        value={formData.head_teacher_name}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-700">Phone Number</td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <input
                                        type="text"
                                        name="head_teacher_phone_number"
                                        value={formData.head_teacher_phone_number}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-700">Signature</td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <input
                                        type="text"
                                        name="headTeacherSignature"
                                        value=""
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-700">Date</td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <input
                                        type="date"
                                        name="headTeacherDate"
                                        value=""
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    />
                                </td>
                            </tr>

                        </tbody>
                    </table>

                    <div>
                        <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-700">Proprietor’s Signature</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                            <input
                                type="text"
                                name="proprietorSignature"
                                value=""
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                            />
                        </td>
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Please indicate below what you liked about this form and what you found difficult.</label>
                        <textarea
                            name="likedAboutForm"
                            value=""
                            onChange={handleInputChange}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Found Difficult</label>
                        <textarea
                            name="foundDifficult"
                            value=""
                            onChange={handleInputChange}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">What Next</label>
                        <textarea
                            name="whatNext"
                            value=""
                            onChange={handleInputChange}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        />
                    </div>
                    <p className="mb-4 text-center">THANK YOU! </p>

                    <p className="mb-4 text-center">Your help will enable us to maintain an effective grading assessment system.</p>

                </fieldset>
                <p className='text-center font-bold'>23</p>


                <div className="text-center mt-4">
                    <button
                        type="submit"
                        className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        {isLoading ? <i
                            className={'fas fa-circle-notch fa-spin text-base'}
                        ></i> : 'Save'}
                    </button>
                </div>
            </form>
        </div>
    );
};



export default GradingForm;
