import React from 'react';
import { useTable } from 'react-table';
import { Bar } from 'react-chartjs-2';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import 'tailwindcss/tailwind.css';

const SchoolRegistrationSummaryByLGA = ({ data }) => {
  const columns = React.useMemo(
    () => [
      { Header: 'SN', accessor: (row, i) => i + 1 },
      { Header: 'LGA', accessor: 'lga' },
      { Header: 'No of Schools', accessor: 'schools' },
      { Header: 'Number of Girls', accessor: 'girls' },
      { Header: 'Number of Boys', accessor: 'boys' },
      { Header: 'Total Learners', accessor: 'total' },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
  } = useTable({ columns, data });

  const chartData = {
    labels: data.map(d => d.lga),
    datasets: [
      {
        label: 'Number of Schools',
        data: data.map(d => d.schools),
        backgroundColor: 'rgba(66, 133, 244, 0.6)',
      },
      {
        label: 'Number of Girls',
        data: data.map(d => d.girls),
        backgroundColor: 'rgba(219, 68, 55, 0.6)',
      },
      {
        label: 'Number of Boys',
        data: data.map(d => d.boys),
        backgroundColor: 'rgba(244, 180, 0, 0.6)',
      },
      {
        label: 'Total Learners',
        data: data.map(d => d.total),
        backgroundColor: 'rgba(15, 157, 88, 0.6)',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 14, // Increase the font size for better readability
          },
        },
      },
      x: {
        ticks: {
          font: {
            size: 14, // Increase the font size for better readability
          },
        },
      },
    },
  };

  return (
    <div className="bg-white shadow-md border border-gray-50 rounded-lg p-6 mb-8">
      <h3 className="text-xl font-semibold mb-4">School's Registration Summary By LGA</h3>
      <Tabs>
        <TabList>
          <Tab>Chart</Tab>
          <Tab>Table</Tab>
        </TabList>

        <TabPanel>
          <div className="h-96">
            <Bar data={chartData} options={chartOptions} />
          </div>
        </TabPanel>

        <TabPanel>
          <div className="overflow-x-auto">
            <div className="max-h-64 overflow-y-auto">
              <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th
                          {...column.getHeaderProps()}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className={i % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                        {row.cells.map(cell => (
                          <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap">
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot className="bg-gray-50">
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap font-semibold">Total</td>
                    <td className="px-6 py-4 whitespace-nowrap"></td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {data.reduce((sum, item) => sum + item.schools, 0)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {data.reduce((sum, item) => sum + item.girls, 0)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {data.reduce((sum, item) => sum + item.boys, 0)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {data.reduce((sum, item) => sum + item.total, 0)}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default SchoolRegistrationSummaryByLGA;
