import React from 'react';

const RevenueCard = ({ title, total, cash, card,index }) => {
    //var NumberFormat = require('react-currency-format')
    return (
        <div className={` ${index === 1 ? `bg-custom-green` : `${index ===2 ? `bg-custom-yellow`: `${index === 3 ? `bg-custom-redish`:`bg-custom-blue`}`}`} shadow-md border border-gray rounded-lg p-3 mb-4`}>
            <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
            <p className="text-xl font-semibold text-white mb-4">
                {/* <NumberFormat
                    value={total}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'₦'}
                /> */}
                {total}
            </p>
            <div className="flex justify-between">
                <div className="text-white">
                    <p className="font-medium">CASH</p>
                    <p className="">
                        {/* <NumberFormat
                            value={cash}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'₦'}
                        /> */}
                        {cash}
                    </p>
                </div>
                <div className="text-white">
                    <p className="font-medium">CARD</p>
                    <p className="">
                        {/* <NumberFormat
                            value={card}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'₦'}
                        /> */}
                        {card}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RevenueCard;
