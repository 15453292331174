import React, { createContext, useContext } from 'react';

// Create a context
const SyncLgaContext = createContext();

// Create a provider component
export const SyncLgaProvider = ({ children }) => {
    const syncLga = [
        {
          id: '1AB',
          name: 'ABA NORTH',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '2AB',
          name: 'ABA SOUTH',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '3AB',
          name: 'AROCHUKWU',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '4AB',
          name: 'BENDE',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '5AB',
          name: 'IKWUANO',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '6AB',
          name: 'ISIALA NGWA NORTH',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '7AB',
          name: 'ISIALA NGWA SOUTH',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '8AB',
          name: 'ISUIKWUATO',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '9AB',
          name: 'OBINGWA',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '10AB',
          name: 'OHAFIA',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '11AB',
          name: 'OSISIOMA',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '12AB',
          name: 'UGWUNAGBO',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '13AB',
          name: 'UKWA EAST',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '14AB',
          name: 'UKWA  WEST',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '15AB',
          name: 'UMUAHIA NORTH',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '16AB',
          name: 'UMUAHIA  SOUTH',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '17AB',
          name: 'UMU - NNEOCHI',
          stateid: 'AB',
          statename: '',
        },
    
        {
          id: '1AD',
          name: 'DEMSA',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '2AD',
          name: 'FUFORE',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '3AD',
          name: 'GANYE',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '4AD',
          name: 'GIRE 1',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '5AD',
          name: 'GOMBI',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '6AD',
          name: 'GUYUK',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '7AD',
          name: 'HONG',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '8AD',
          name: 'JADA',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '9AD',
          name: 'LAMURDE',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '10AD',
          name: 'MADAGALI',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '11AD',
          name: 'MAIHA',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '12AD',
          name: 'MAYO - BELWA',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '13AD',
          name: 'MICHIKA',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '14AD',
          name: 'MUBI NORTH',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '15AD',
          name: 'MUBI SOUTH',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '16AD',
          name: 'NUMAN',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '17AD',
          name: 'SHELLENG',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '18AD',
          name: 'SONG',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '19AD',
          name: 'TOUNGO',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '20AD',
          name: 'YOLA NORTH',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '21AD',
          name: 'YOLA SOUTH',
          stateid: 'AD',
          statename: '',
        },
    
        {
          id: '1AK',
          name: 'ABAK',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '2AK',
          name: 'EASTERN OBOLO',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '3AK',
          name: 'EKET',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '4AK',
          name: 'ESIT EKET (UQUO)',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '5AK',
          name: 'ESSIEN UDIM',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '6AK',
          name: 'ETIM EKPO',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '7AK',
          name: 'ETINAN',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '8AK',
          name: 'IBENO',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '9AK',
          name: 'IBESIKPO ASUTAN',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '10AK',
          name: 'IBIONO IBOM',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '11AK',
          name: 'IKA',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '12AK',
          name: 'IKONO',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '13AK',
          name: 'IKOT ABASI',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '14AK',
          name: 'IKOT EKPENE',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '15AK',
          name: 'INI',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '16AK',
          name: 'ITU',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '17AK',
          name: 'MBO',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '18AK',
          name: 'MKPAT ENIN',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '19AK',
          name: 'NSIT ATAI',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '20AK',
          name: 'NSIT IBOM',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '21AK',
          name: 'NSIT UBIUM',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '22AK',
          name: 'OBOT AKARA',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '23AK',
          name: 'OKOBO',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '24AK',
          name: 'ONNA',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '25AK',
          name: 'ORON',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '26AK',
          name: 'ORUK ANAM',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '27AK',
          name: 'UDUNG UKO',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '28AK',
          name: 'UKANAFUN',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '29AK',
          name: 'URUAN',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '30AK',
          name: 'URUE OFFONG ORUKO',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '31AK',
          name: 'UYO',
          stateid: 'AK',
          statename: '',
        },
    
        {
          id: '1AN',
          name: 'AGUATA',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '2AN',
          name: 'AYAMELUM',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '3AN',
          name: 'ANAMBRA EAST',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '4AN',
          name: 'ANAMBRA WEST',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '5AN',
          name: 'ANAOCHA',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '6AN',
          name: 'AWKA NORTH',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '7AN',
          name: 'AWKA SOUTH',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '8AN',
          name: 'DUNUKOFIA',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '9AN',
          name: 'EKWUSIGO',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '10AN',
          name: 'IDEMILI NORTH',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '11AN',
          name: 'IDEMILI-SOUTH',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '12AN',
          name: 'IHALA',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '13AN',
          name: 'NJIKOKA',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '14AN',
          name: 'NNEWI NORTH',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '15AN',
          name: 'NNEWI SOUTH',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '16AN',
          name: 'OGBARU',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '17AN',
          name: 'ONITSHA-NORTH',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '18AN',
          name: 'ONITSHA -SOUTH',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '19AN',
          name: 'ORUMBA NORTH',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '20AN',
          name: 'ORUMBA  SOUTH',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '21AN',
          name: 'OYI',
          stateid: 'AN',
          statename: '',
        },
    
        {
          id: '1BA',
          name: 'ALKALERI',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '2BA',
          name: 'BAUCHI',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '3BA',
          name: 'BOGORO',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '4BA',
          name: 'DAMBAM',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '5BA',
          name: 'DARAZO',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '6BA',
          name: 'DASS',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '7BA',
          name: 'GAMAWA',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '8BA',
          name: 'GANJUWA',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '9BA',
          name: 'GIADE',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '10BA',
          name: 'ITAS/GADAU',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '11BA',
          name: "JAMA'ARE",
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '12BA',
          name: 'KATAGUM',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '13BA',
          name: 'KIRFI',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '14BA',
          name: 'MISAU',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '15BA',
          name: 'NINGI',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '16BA',
          name: 'SHIRA',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '17BA',
          name: 'TAFAWA BALEWA',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '18BA',
          name: 'TORO',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '19BA',
          name: 'WARJI',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '20BA',
          name: 'ZAKI',
          stateid: 'BA',
          statename: '',
        },
    
        {
          id: '1BY',
          name: 'BRASS',
          stateid: 'BY',
          statename: '',
        },
    
        {
          id: '2BY',
          name: 'EKEREMOR',
          stateid: 'BY',
          statename: '',
        },
    
        {
          id: '3BY',
          name: 'KOLOKUMA/OPOKUMA',
          stateid: 'BY',
          statename: '',
        },
    
        {
          id: '4BY',
          name: 'NEMBE',
          stateid: 'BY',
          statename: '',
        },
    
        {
          id: '5BY',
          name: 'OGBIA',
          stateid: 'BY',
          statename: '',
        },
    
        {
          id: '6BY',
          name: 'SAGBAMA',
          stateid: 'BY',
          statename: '',
        },
    
        {
          id: '7BY',
          name: 'SOUTHERN IJAW',
          stateid: 'BY',
          statename: '',
        },
    
        {
          id: '8BY',
          name: 'YENAGOA',
          stateid: 'BY',
          statename: '',
        },
    
        {
          id: '1BN',
          name: 'ADO',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '2BN',
          name: 'AGATU',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '3BN',
          name: 'APA',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '4BN',
          name: 'BURUKU',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '5BN',
          name: 'GBOKO',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '6BN',
          name: 'GUMA',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '7BN',
          name: 'GWER EAST',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '8BN',
          name: 'GWER WEST',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '9BN',
          name: 'KATSINA-ALA',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '10BN',
          name: 'KONSHISHA',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '11BN',
          name: 'KWANDE',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '12BN',
          name: 'LOGO',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '13BN',
          name: 'MAKURDI',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '14BN',
          name: 'OBI',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '15BN',
          name: 'OGBADIBO',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '16BN',
          name: 'OJU',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '17BN',
          name: 'OHIMINI',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '18BN',
          name: 'OKPOKWU',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '19BN',
          name: 'OTUKPO',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '20BN',
          name: 'TARKA',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '21BN',
          name: 'UKUM',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '22BN',
          name: 'USHONGO',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '23BN',
          name: 'VANDEIKYA',
          stateid: 'BN',
          statename: '',
        },
    
        {
          id: '1BO',
          name: 'ABADAM',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '2BO',
          name: 'ASKIRA / UBA',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '3BO',
          name: 'BAMA',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '4BO',
          name: 'BAYO',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '5BO',
          name: 'BIU',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '6BO',
          name: 'CHIBOK',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '7BO',
          name: 'DAMBOA',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '8BO',
          name: 'DIKWA',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '9BO',
          name: 'GUBIO',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '10BO',
          name: 'GUZAMALA',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '11BO',
          name: 'GWOZA',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '12BO',
          name: 'HAWUL',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '13BO',
          name: 'JERE',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '14BO',
          name: 'KAGA',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '15BO',
          name: 'KALA BALGE',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '16BO',
          name: 'KONDUGA',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '17BO',
          name: 'KUKAWA',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '18BO',
          name: 'KWAYA / KUSAR',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '19BO',
          name: 'MAFA',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '20BO',
          name: 'MAGUMERI',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '21BO',
          name: 'MAIDUGURI M. C.',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '22BO',
          name: 'MARTE',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '23BO',
          name: 'MOBBAR',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '24BO',
          name: 'MONGUNO',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '25BO',
          name: 'NGALA',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '26BO',
          name: 'NGANZAI',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '27BO',
          name: 'SHANI',
          stateid: 'BO',
          statename: '',
        },
    
        {
          id: '1CR',
          name: 'ABI',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '2CR',
          name: 'AKAMKPA',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '3CR',
          name: 'AKPABUYO',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '4CR',
          name: 'BAKASSI',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '5CR',
          name: 'BEKWARRA',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '6CR',
          name: 'BIASE',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '7CR',
          name: 'BOKI',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '8CR',
          name: 'CALABAR MUNICIPALITY',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '9CR',
          name: 'CALABAR SOUTH',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '10CR',
          name: 'ETUNG',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '11CR',
          name: 'IKOM',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '12CR',
          name: 'OBANLIKU',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '13CR',
          name: 'OBUBRA',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '14CR',
          name: 'OBUDU',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '15CR',
          name: 'ODUKPANI',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '16CR',
          name: 'OGOJA',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '17CR',
          name: 'YAKURR',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '18CR',
          name: 'YALA',
          stateid: 'CR',
          statename: '',
        },
    
        {
          id: '1DT',
          name: 'ANIOCHA NORTH',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '2DT',
          name: 'ANIOCHA - SOUTH',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '3DT',
          name: 'BOMADI',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '4DT',
          name: 'BURUTU',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '5DT',
          name: 'ETHIOPE  EAST',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '6DT',
          name: 'ETHIOPE  WEST',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '7DT',
          name: 'IKA NORTH- EAST',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '8DT',
          name: 'IKA - SOUTH',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '9DT',
          name: 'ISOKO NORTH',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '10DT',
          name: 'ISOKO SOUTH',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '11DT',
          name: 'NDOKWA EAST',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '12DT',
          name: 'NDOKWA WEST',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '13DT',
          name: 'OKPE',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '14DT',
          name: 'OSHIMILI - NORTH',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '15DT',
          name: 'OSHIMILI - SOUTH',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '16DT',
          name: 'PATANI',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '17DT',
          name: 'SAPELE',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '18DT',
          name: 'UDU',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '19DT',
          name: 'UGHELLI NORTH',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '20DT',
          name: 'UGHELLI SOUTH',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '21DT',
          name: 'UKWUANI',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '22DT',
          name: 'UVWIE',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '23DT',
          name: 'WARRI  NORTH',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '24DT',
          name: 'WARRI SOUTH',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '25DT',
          name: 'WARRI SOUTH  WEST',
          stateid: 'DT',
          statename: '',
        },
    
        {
          id: '1EB',
          name: 'ABAKALIKI',
          stateid: 'EB',
          statename: '',
        },
    
        {
          id: '2EB',
          name: 'AFIKPO NORTH',
          stateid: 'EB',
          statename: '',
        },
    
        {
          id: '3EB',
          name: 'AFIKPO  SOUTH',
          stateid: 'EB',
          statename: '',
        },
    
        {
          id: '4EB',
          name: 'EBONYI',
          stateid: 'EB',
          statename: '',
        },
    
        {
          id: '5EB',
          name: 'EZZA NORTH',
          stateid: 'EB',
          statename: '',
        },
    
        {
          id: '6EB',
          name: 'EZZA SOUTH',
          stateid: 'EB',
          statename: '',
        },
    
        {
          id: '7EB',
          name: 'IKWO',
          stateid: 'EB',
          statename: '',
        },
    
        {
          id: '8EB',
          name: 'ISHIELU',
          stateid: 'EB',
          statename: '',
        },
    
        {
          id: '9EB',
          name: 'IVO',
          stateid: 'EB',
          statename: '',
        },
    
        {
          id: '10EB',
          name: 'IZZI',
          stateid: 'EB',
          statename: '',
        },
    
        {
          id: '11EB',
          name: 'OHAOZARA',
          stateid: 'EB',
          statename: '',
        },
    
        {
          id: '12EB',
          name: 'OHAUKWU',
          stateid: 'EB',
          statename: '',
        },
    
        {
          id: '13EB',
          name: 'ONICHA',
          stateid: 'EB',
          statename: '',
        },
    
        {
          id: '1ED',
          name: 'AKOKO EDO',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '2ED',
          name: 'EGOR',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '3ED',
          name: 'ESAN CENTRAL',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '4ED',
          name: 'ESAN NORTH EAST',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '5ED',
          name: 'ESAN SOUTH EAST',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '6ED',
          name: 'ESAN WEST',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '7ED',
          name: 'ETSAKO CENTRAL',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '8ED',
          name: 'ETSAKO EAST',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '9ED',
          name: 'ETSAKO  WEST',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '10ED',
          name: 'IGUEBEN',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '11ED',
          name: 'IKPOBA/OKHA',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '12ED',
          name: 'OREDO',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '13ED',
          name: 'ORHIONMWON',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '14ED',
          name: 'OVIA NORTH EAST',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '15ED',
          name: 'OVIA SOUTH WEST',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '16ED',
          name: 'OWAN EAST',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '17ED',
          name: 'OWAN WEST',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '18ED',
          name: 'UHUNMWODE',
          stateid: 'ED',
          statename: '',
        },
    
        {
          id: '1EK',
          name: 'ADO EKITI',
          stateid: 'EK',
          statename: '',
        },
    
        {
          id: '2EK',
          name: 'EFON',
          stateid: 'EK',
          statename: '',
        },
    
        {
          id: '3EK',
          name: 'EKITI EAST',
          stateid: 'EK',
          statename: '',
        },
    
        {
          id: '4EK',
          name: 'EKITI WEST',
          stateid: 'EK',
          statename: '',
        },
    
        {
          id: '5EK',
          name: 'EKITI SOUTH WEST',
          stateid: 'EK',
          statename: '',
        },
    
        {
          id: '6EK',
          name: 'EMURE',
          stateid: 'EK',
          statename: '',
        },
    
        {
          id: '7EK',
          name: 'GBONYIN',
          stateid: 'EK',
          statename: '',
        },
    
        {
          id: '8EK',
          name: 'IDO / OSI',
          stateid: 'EK',
          statename: '',
        },
    
        {
          id: '9EK',
          name: 'IJERO',
          stateid: 'EK',
          statename: '',
        },
    
        {
          id: '10EK',
          name: 'IKERE',
          stateid: 'EK',
          statename: '',
        },
    
        {
          id: '11EK',
          name: 'IKOLE',
          stateid: 'EK',
          statename: '',
        },
    
        {
          id: '12EK',
          name: 'ILEJEMEJE',
          stateid: 'EK',
          statename: '',
        },
    
        {
          id: '13EK',
          name: 'IREPODUN / IFELODUN',
          stateid: 'EK',
          statename: '',
        },
    
        {
          id: '14EK',
          name: 'ISE / ORUN',
          stateid: 'EK',
          statename: '',
        },
    
        {
          id: '15EK',
          name: 'MOBA',
          stateid: 'EK',
          statename: '',
        },
    
        {
          id: '16EK',
          name: 'OYE',
          stateid: 'EK',
          statename: '',
        },
    
        {
          id: '1EN',
          name: 'ANINRI',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '2EN',
          name: 'AWGU',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '3EN',
          name: 'ENUGU EAST',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '4EN',
          name: 'ENUGU NORTH',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '5EN',
          name: 'ENUGU SOUTH',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '6EN',
          name: 'EZEAGU',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '7EN',
          name: 'IGBO ETITI',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '8EN',
          name: 'IGBO EZE NORTH',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '9EN',
          name: 'IGBO EZE SOUTH',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '10EN',
          name: 'ISI UZO',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '11EN',
          name: 'NKANU EAST',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '12EN',
          name: 'NKANU WEST',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '13EN',
          name: 'NSUKKA',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '14EN',
          name: 'OJI-RIVER',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '15EN',
          name: 'UDENU',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '16EN',
          name: 'UDI',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '17EN',
          name: 'UZO-UWANI',
          stateid: 'EN',
          statename: '',
        },
    
        {
          id: '1FCT',
          name: 'ABAJI',
          stateid: 'FCT',
          statename: '',
        },
    
        {
          id: '2FCT',
          name: 'BWARI',
          stateid: 'FCT',
          statename: '',
        },
    
        {
          id: '3FCT',
          name: 'GWAGWALADA',
          stateid: 'FCT',
          statename: '',
        },
    
        {
          id: '4FCT',
          name: 'KUJE',
          stateid: 'FCT',
          statename: '',
        },
    
        {
          id: '5FCT',
          name: 'KWALI',
          stateid: 'FCT',
          statename: '',
        },
    
        {
          id: '6FCT',
          name: 'MUNICIPAL',
          stateid: 'FCT',
          statename: '',
        },
    
        {
          id: '1GM',
          name: 'AKKO',
          stateid: 'GM',
          statename: '',
        },
    
        {
          id: '2GM',
          name: 'BALANGA',
          stateid: 'GM',
          statename: '',
        },
    
        {
          id: '3GM',
          name: 'BILLIRI',
          stateid: 'GM',
          statename: '',
        },
    
        {
          id: '4GM',
          name: 'DUKKU',
          stateid: 'GM',
          statename: '',
        },
    
        {
          id: '5GM',
          name: 'FUNAKAYE',
          stateid: 'GM',
          statename: '',
        },
    
        {
          id: '6GM',
          name: 'GOMBE',
          stateid: 'GM',
          statename: '',
        },
    
        {
          id: '7GM',
          name: 'KALTUNGO',
          stateid: 'GM',
          statename: '',
        },
    
        {
          id: '8GM',
          name: 'KWAMI',
          stateid: 'GM',
          statename: '',
        },
    
        {
          id: '9GM',
          name: 'NAFADA',
          stateid: 'GM',
          statename: '',
        },
    
        {
          id: '10GM',
          name: 'SHONGOM',
          stateid: 'GM',
          statename: '',
        },
    
        {
          id: '11GM',
          name: 'YALMALTU/ DEBA',
          stateid: 'GM',
          statename: '',
        },
    
        {
          id: '1IM',
          name: 'ABOH MBAISE',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '2IM',
          name: 'AHIAZU MBAISE',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '3IM',
          name: 'EHIME MBANO',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '4IM',
          name: 'EZINIHITTE MBAISE',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '5IM',
          name: 'IDEATO NORTH',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '6IM',
          name: 'IDEATO SOUTH',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '7IM',
          name: 'IHITTE/UBOMA (ISINWEKE)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '8IM',
          name: 'IKEDURU (IHO)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '9IM',
          name: 'ISIALA MBANO (UMUELEMAI)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '10IM',
          name: 'ISU (UMUNDUGBA)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '11IM',
          name: 'MBAITOLI (NWAORIEUBI)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '12IM',
          name: 'NGOR OKPALA (UMUNEKE)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '13IM',
          name: 'NJABA (NNENASA)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '14IM',
          name: 'NWANGELE (ONU-NWANGELE AMAIGBO)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '15IM',
          name: 'NKWERRE',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '16IM',
          name: 'OBOWO (OTOKO)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '17IM',
          name: 'OGUTA (OGUTA)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '18IM',
          name: 'OHAJI/EGBEMA (MMAHU-EGBEMA)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '19IM',
          name: 'OKIGWE  (OKIGWE)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '20IM',
          name: 'ONUIMO (OKWE)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '21IM',
          name: 'ORLU',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '22IM',
          name: 'ORSU (AWO IDEMILI)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '23IM',
          name: 'ORU-EAST',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '24IM',
          name: 'ORU WEST (MGBIDI)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '25IM',
          name: 'OWERRI URBAN (OWERRI)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '26IM',
          name: 'OWERRI NORTH (ORIE URATTA)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '27IM',
          name: 'OWERRI WEST (UMUGUMA)',
          stateid: 'IM',
          statename: '',
        },
    
        {
          id: '1JG',
          name: 'AUYO',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '2JG',
          name: 'BABURA',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '3JG',
          name: 'BIRNIN KUDU',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '4JG',
          name: 'BIRNIWA',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '5JG',
          name: 'BUJI',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '6JG',
          name: 'DUTSE',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '7JG',
          name: 'GARKI',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '8JG',
          name: 'GAGARAWA',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '9JG',
          name: 'GUMEL',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '10JG',
          name: 'GURI',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '11JG',
          name: 'GWARAM',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '12JG',
          name: 'GWIWA',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '13JG',
          name: 'HADEJIA',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '14JG',
          name: 'JAHUN',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '15JG',
          name: 'KAFIN HAUSA',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '16JG',
          name: 'KAUGAMA',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '17JG',
          name: 'KAZAURE',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '18JG',
          name: 'KIRIKA SAMMA',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '19JG',
          name: 'KIYAWA',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '20JG',
          name: 'MAIGATARI',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '21JG',
          name: 'MALAM MADORI',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '22JG',
          name: 'MIGA',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '23JG',
          name: 'RINGIM',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '24JG',
          name: 'RONI',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '25JG',
          name: 'SULE-TANKARKAR',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '26JG',
          name: 'TAURA',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '27JG',
          name: 'YANKWASHI',
          stateid: 'JG',
          statename: '',
        },
    
        {
          id: '1KD',
          name: 'BIRNIN GWARI',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '2KD',
          name: 'CHIKUN',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '3KD',
          name: 'GIWA',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '4KD',
          name: 'IGABI',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '5KD',
          name: 'IKARA',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '6KD',
          name: 'JABA',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '7KD',
          name: "JEMA'A",
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '8KD',
          name: 'KACHIA',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '9KD',
          name: 'KADUNA NORTH',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '10KD',
          name: 'KADUNA SOUTH',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '11KD',
          name: 'KAGARKO',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '12KD',
          name: 'KAJURU',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '13KD',
          name: 'KAURA',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '14KD',
          name: 'KAURU',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '15KD',
          name: 'KUBAU',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '16KD',
          name: 'KUDAN',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '17KD',
          name: 'LERE',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '18KD',
          name: 'MAKARFI',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '19KD',
          name: 'SABON GARI',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '20KD',
          name: 'SANGA',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '21KD',
          name: 'SOBA',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '22KD',
          name: 'ZANGON KATAF',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '23KD',
          name: 'ZARIA',
          stateid: 'KD',
          statename: '',
        },
    
        {
          id: '1KN',
          name: 'AJINGI',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '2KN',
          name: 'ALBASU',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '3KN',
          name: 'BAGWAI',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '4KN',
          name: 'BEBEJI',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '5KN',
          name: 'BICHI',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '6KN',
          name: 'BUNKURE',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '7KN',
          name: 'DALA',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '8KN',
          name: 'DANBATA',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '9KN',
          name: 'DAWAKI KUDU',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '10KN',
          name: 'DAWAKI TOFA',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '11KN',
          name: 'DOGUWA',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '12KN',
          name: 'FAGGE',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '13KN',
          name: 'GABASAWA',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '14KN',
          name: 'GARKO',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '15KN',
          name: 'GARUN MALAM',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '16KN',
          name: 'GAYA',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '17KN',
          name: 'GEZAWA',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '18KN',
          name: 'GWALE',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '19KN',
          name: 'GWARZO',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '20KN',
          name: 'KABO',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '21KN',
          name: 'KANO MUNICIPAL',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '22KN',
          name: 'KARAYE',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '23KN',
          name: 'KIBIYA',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '24KN',
          name: 'KIRU',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '25KN',
          name: 'KUMBOTSO',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '26KN',
          name: 'KUNCHI',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '27KN',
          name: 'KURA',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '28KN',
          name: 'MADOBI',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '29KN',
          name: 'MAKODA',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '30KN',
          name: 'MINJIBIR',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '31KN',
          name: 'NASARAWA',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '32KN',
          name: 'RANO',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '33KN',
          name: 'RIMIN GADO',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '34KN',
          name: 'ROGO',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '35KN',
          name: 'SHANONO',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '36KN',
          name: 'SUMAILA',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '37KN',
          name: 'TAKAI',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '38KN',
          name: 'TARAUNI',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '39KN',
          name: 'TOFA',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '40KN',
          name: 'TSANYAWA',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '41KN',
          name: 'TUDUN WADA',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '42KN',
          name: 'UNGOGO',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '43KN',
          name: 'WARAWA',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '44KN',
          name: 'WUDIL',
          stateid: 'KN',
          statename: '',
        },
    
        {
          id: '1KT',
          name: 'BAKORI',
          stateid: 'KT',
          statename: '',
        },
    
        {
          id: '2KT',
          name: 'BATAGARAWA',
          stateid: 'KT',
          statename: '',
        },
    
        {
          id: '3KT',
          name: 'BATSARI',
          stateid: 'KT',
          statename: '',
        },
    
        {
          id: '4KT',
          name: 'BAURE',
          stateid: 'KT',
          statename: '',
        },
    
        {
          id: '5KT',
          name: 'BINDAWA',
          stateid: 'KT',
          statename: '',
        },
    
      ]

  return (
    <SyncLgaContext.Provider value={syncLga}>
      {children}
    </SyncLgaContext.Provider>
  );
};

// Create a custom hook to use the SyncLgaContext
export const useSyncLga = () => {
  return useContext(SyncLgaContext);
};
