// SchoolRecords.js
import React, { useEffect, useState, useCallback } from 'react';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import app from '../firebase/firebase'
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaDownload } from 'react-icons/fa';
import SkeletonLoading from './SkeletonLoading';

import { useSyncLga } from './SyncLgaContext'


const db = getFirestore(app);

const SchoolReport = () => {
    const syncLga = useSyncLga();
    const [records, setRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [filter, setFilter] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const getTotalRecords = useCallback(async () => {
        try {
            const cachedRecords = localStorage.getItem('grading');
            if (cachedRecords) {
                const cachedGrading = JSON.parse(cachedRecords);
                if (cachedGrading.length !== 0) {
                    setRecords(cachedGrading);
                    setIsLoading(false);
                } else {
                    await fetchData();
                }
            } else {
                await fetchData();
            }
        } catch (error) {
            console.error('Error fetching or parsing cached records:', error);
            await fetchData();
        } finally {
            setIsLoading(false);
        }
    }, []);
    

    useEffect(() => {
        getTotalRecords()
    }, [getTotalRecords]);

    const fetchData = async () => {
        setIsLoading(true)
        const querySnapshot = await getDocs(collection(db, 'grading'));
        const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setRecords(data);
        setIsLoading(false)
        localStorage.setItem('grading', JSON.stringify(data));
    };


    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = records.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(records.length / recordsPerPage);

    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePageSizeChange = (event) => {
        setRecordsPerPage(Number(event.target.value));
        //setCurrentPage(1);
    };
    const getLgaNameById = (id) => {
        const lga = syncLga.find(lga => lga.id === id);
        return lga ? lga.name: id;
    };

    const handleFilter = (event) => {
        setFilter(event.target.value.toLowerCase());
    };

    const downloadCSV_old = () => {
        const csvContent = [
            ['S/N', 'School Names', 'Previous School Names', 'Address', 'Year Of Establishment', 'L.G.A', 'School Email', 'School Phone', 'Proprietors Name', 'No. Of Female Learners', 'No Of Male Learners', 'No Learners', 'No. Of Female Teachers', 'No Of Male Teachers', 'No. Of Teachers(PWD)'].join(','),
            ...records.map((record, index) =>
                [index + 1, record.school_names, record.prev_school_names, record.school_address, record.year_of_establishment, getLgaNameById(record.lga_id), record.school_email, record.school_phone, record.proprietors_name, record.total_girls_enrolment, record.total_boys_enrolment, parseInt(record.total_girls_enrolment) + parseInt(record.total_boys_enrolment), record.no_teachers_female, record.no_teachers_male, record.no_teachers_pwd].join(',')
            )
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'records.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const downloadCSV = () => {
        const escapeCSVValue = (value) => {
            // Handle null or undefined values
            if (value == null) {
                return '';
            }
    
            // Convert numbers to string (including handling NaN and Infinity)
            if (typeof value === 'number' && !isNaN(value) && value !== Infinity && value !== -Infinity) {
                return String(value);
            }
    
            // Convert boolean values to string
            if (typeof value === 'boolean') {
                return value ? 'true' : 'false';
            }
    
            // For other types (objects, arrays), convert to JSON string
            if (typeof value === 'object') {
                return JSON.stringify(value);
            }
    
            // For strings, check special CSV characters and escape if needed
            if (typeof value === 'string') {
                // If the value contains commas, enclose it in double quotes and escape existing quotes
                if (value.includes(',')) {
                    return `"${value.replace(/"/g, '""')}"`;
                }
                return value;
            }
    
            // Fallback for any unexpected type
            return String(value);
        };
    
        const csvHeader = [
            'S/N', 'School Names', 'Previous School Names', 'Address', 'Year Of Establishment',
            'Annual Renewal Year 2021', 'Annual Renewal Year 2022', 'Annual Renewal Year 2023',
            'L.G.A', 'School Email', 'School Phone', 'Proprietors Name', 'No. Of Female Learners',
            'No Of Male Learners', 'No Learners', 'No. Of Female Teachers', 'No Of Male Teachers',
            'No. Of Teachers(PWD)'
        ].map(value => escapeCSVValue(value)).join(',');
    
        const csvRows = records.map((record, index) => {
            const row = [
                index + 1, record.school_names, record.prev_school_names, record.school_address,
                record.year_of_establishment, record.annual_renewal_year2021, record.annual_renewal_year2022, 
                record.annual_renewal_year2023, getLgaNameById(record.lga_id), record.school_email,
                record.school_phone, record.proprietors_name, record.total_girls_enrolment,
                record.total_boys_enrolment,
                (record.total_girls_enrolment || 0) + (record.total_boys_enrolment || 0),
                record.no_teachers_female, record.no_teachers_male, record.no_teachers_pwd
            ];
            return row.map(value => escapeCSVValue(value)).join(',');
        }).join('\n');
    
        const csvContent = `${csvHeader}\n${csvRows}`;
    
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'records.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    

    if (isLoading) return <SkeletonLoading />

    return (
        <div className="container mx-auto px-4 py-8 ">

            <div className="flex justify-between items-center mb-4">
                {/* <div>
                    <input
                        type="text"
                        placeholder="Filter by any column..."
                        onChange={handleFilter}
                        className="border border-gray-300 rounded p-2"
                    />
                </div> */}
                <div>
                    <button onClick={downloadCSV} className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">
                        <FaDownload className="mr-2" />
                        Download CSV
                    </button>
                </div>
            </div>

            <div className='overflow-x-auto'>
                <table className=" bg-white border border-gray-300">
                    <thead className="bg-gray-200 text-gray-600 text-sm leading-normal">
                        <tr>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">S/N</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">School Names</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Previous School Names</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Address</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Year Of Establishment</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Annual Renewal Year 2021</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Annual Renewal Year 2022</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Annual Renewal Year 2023</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">L.G.A</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">School Email</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">School Phone</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Proprietors Name</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">No. Of Female Learners</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">No Of Male Learners</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">No Learners</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">No. Of Female Teachers</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">No Of Male Teachers</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">No. Of Teachers(PWD)</th>
                            {/* <th className="py-3 px-4 text-left whitespace-nowrap">Action</th> */}
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">

                        {currentRecords.map((record, index) => (
                            <tr key={record.id} className="border-b border-gray-200 hover:bg-gray-100">
                                <td className="py-3 px-4 text-left whitespace-nowrap">{(currentPage - 1) * recordsPerPage + index + 1}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.school_names}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.prev_school_names}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.school_address}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.year_of_establishment}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.annual_renewal_year2021}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.annual_renewal_year2022}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.annual_renewal_year2023}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{getLgaNameById(record.lga_id)}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.school_email}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.school_phone_nos}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.proprietors_name}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.total_girls_enrolment}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.total_boys_enrolment}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{parseInt(record.total_girls_enrolment) + parseInt(record.total_boys_enrolment)}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.no_teachers_female}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.no_teachers_male}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.no_teachers_pwd}</td>
                                {/* <td className="py-3 px-4 text-left whitespace-nowrap">
                                <button className="text-blue-500 hover:text-blue-700">
                                    <FaEdit />
                                </button>
                                <button className="text-red-500 hover:text-red-700 ml-2">
                                    <FaTrash />
                                </button>
                            </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="flex justify-between items-center py-4">
                <div className="flex items-center space-x-2">
                    <button
                        className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                        onClick={() => handleClick(1)}
                        disabled={currentPage === 1}
                    >
                        <FaAngleDoubleLeft />
                    </button>
                    <button
                        className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                        onClick={() => handleClick(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <FaAngleLeft />
                    </button>
                    <span className="text-gray-600 mx-2">
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                        onClick={() => handleClick(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <FaAngleRight />
                    </button>
                    <button
                        className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                        onClick={() => handleClick(totalPages)}
                        disabled={currentPage === totalPages}
                    >
                        <FaAngleDoubleRight />
                    </button>
                </div>
                <div className="flex items-center">
                    <label htmlFor="pageSize" className="mr-2 text-gray-600">Page Size:</label>
                    <select
                        id="pageSize"
                        value={recordsPerPage}
                        onChange={handlePageSizeChange}
                        className="border border-gray-300 rounded p-1"
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                    </select>
                </div>
            </div>
        </div>
    );
};
export default SchoolReport;