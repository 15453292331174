const Organogram = () => {
  return (
    <section id="about-us" className="py-20 bg-gray-50">
  <div className="container mx-auto px-5 md:px-32">
    <div className="relative bg-white shadow-lg rounded-lg p-8 md:p-16">
      <img
        src="/media/pdf/Organogram.jpg"
        alt="Organogram"
        className=""
      />
    </div>
  </div>
</section>

  );
};

export default Organogram;
