import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const SchoolSummary = ({ totalRegistrations, completed, uncompleted }) => {
  const completedPercentage = (completed / totalRegistrations) * 100;
  const uncompletedPercentage = (uncompleted / totalRegistrations) * 100;

  return (
    <div className="bg-white shadow rounded-lg p-4">
      <h3 className="text-lg font-semibold mb-4">School Summary</h3>
      <div className="flex justify-around items-center">
        <div className="w-1/3">
          <CircularProgressbar
            value={completedPercentage}
            text={`${completedPercentage.toFixed(0)}%`}
            styles={buildStyles({
              textSize: '16px',
              pathColor: `#4caf50`,
              textColor: '#4caf50'
            })}
          />
          <p className="text-center mt-2 text-sm">Completed</p>
        </div>
        <div className="w-1/3">
          <CircularProgressbar
            value={uncompletedPercentage}
            text={`${uncompletedPercentage.toFixed(0)}%`}
            styles={buildStyles({
              textSize: '16px',
              pathColor: `#f44336`,
              textColor: '#f44336'
            })}
          />
          <p className="text-center mt-2 text-sm">Uncompleted</p>
        </div>
        <div className="w-1/3">
          <CircularProgressbar
            value={100}
            text={`${totalRegistrations}`}
            styles={buildStyles({
              textSize: '16px',
              pathColor: `#2196f3`,
              textColor: '#2196f3'
            })}
          />
          <p className="text-center mt-2 text-sm">Total Registrations</p>
        </div>
      </div>
    </div>
  );
};

export default SchoolSummary;
