// SchoolRecords.js
import React, { useEffect, useState, useCallback } from 'react';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import app from '../firebase/firebase'
import { FaAngleDoubleLeft,FaCheck,FaCheckCircle, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaDownload } from 'react-icons/fa';
import SkeletonLoading from './SkeletonLoading';
import axios from 'axios';
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { useSyncLga } from './SyncLgaContext'
const db = getFirestore(app);

const TransactionSummaryReport = () => {
    const syncLga = useSyncLga();
    const [records, setRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [filter, setFilter] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const fetchTransactions = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(
                'https://us-central1-igr-service.cloudfunctions.net/app/api/summary/profile/all/07', 
                {}, 
                
            );
            
            if (response.status === 200) {
                setRecords(response.data.result);
            } else {
                NotificationManager.error('Failed to retrieve records. Please refresh the page.');
            }
        } catch (error) {
            NotificationManager.error('Network error or server issue. Please try again later.');
            console.error("Error fetching transactions: ", error);
        } finally {
            setIsLoading(false);
        }
    };
    
    
   
    useEffect(() => {
        fetchTransactions()
    }, []);


    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = records.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(records.length / recordsPerPage);

    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePageSizeChange = (event) => {
        setRecordsPerPage(Number(event.target.value));
        //setCurrentPage(1);
    };
    
   
    const downloadCSV = () => {
        const csvContent = [
            ['S/N', 'Station', 'Profile', 'Total Amount', 'Card Amount', 'Cash Amount', 'Amount Remitted', 'Amount Unremitted'].join(','),
            ...records.map((record, index) =>
                [index + 1, record.station, record.profile, record.amount, record.amountCard, record.amountCash, record.cashRemitted, record.cashUnremitted].join(',')
            )
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'records.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };



    if (isLoading) return <SkeletonLoading />

    return (
        <div className="container mx-auto px-4 py-8 ">

            <div className="flex justify-between items-center mb-4">
        
                <div>
                    <button onClick={downloadCSV} className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">
                        <FaDownload className="mr-2" />
                        Download CSV
                    </button>
                </div>
            </div>

            <div className=' overflow-x-auto'>
                <table className="w-full bg-white border border-gray-300">
                    <thead className="bg-gray-200 text-gray-600 text-sm leading-normal">
                        <tr>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">S/N</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Station</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Profile</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Total amount</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Card amount</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Cash amount</th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Remitted </th>
                            <th className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Unremitted </th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">

                        {currentRecords.map((record, index) => (
                            <tr key={record.id} className="border-b border-gray-200 hover:bg-gray-100">
                                <td className="py-3 px-4 text-left whitespace-nowrap">{(currentPage - 1) * recordsPerPage + index + 1}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.station}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.profile}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.amount}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.amountCard}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.amountCash}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.cashRemitted}</td>
                                <td className="py-3 px-4 text-left whitespace-nowrap">{record.cashUnremitted}</td>
    
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="flex justify-between items-center py-4">
                <div className="flex items-center space-x-2">
                    <button
                        className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                        onClick={() => handleClick(1)}
                        disabled={currentPage === 1}
                    >
                        <FaAngleDoubleLeft />
                    </button>
                    <button
                        className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                        onClick={() => handleClick(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <FaAngleLeft />
                    </button>
                    <span className="text-gray-600 mx-2">
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                        onClick={() => handleClick(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <FaAngleRight />
                    </button>
                    <button
                        className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                        onClick={() => handleClick(totalPages)}
                        disabled={currentPage === totalPages}
                    >
                        <FaAngleDoubleRight />
                    </button>
                </div>
                <div className="flex items-center">
                    <label htmlFor="pageSize" className="mr-2 text-gray-600">Page Size:</label>
                    <select
                        id="pageSize"
                        value={recordsPerPage}
                        onChange={handlePageSizeChange}
                        className="border border-gray-300 rounded p-1"
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                    </select>
                </div>
            </div>
        </div>
    );
};
export default TransactionSummaryReport;