import React, { useState } from 'react';
import googleLogo from '../img/icons8-google.svg';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import app from '../firebase/firebase'
import { getAuth, signInWithEmailAndPassword, signInWithPopup ,GoogleAuthProvider } from "firebase/auth";
import { useHistory } from 'react-router-dom';
import { getFirestore } from "firebase/firestore";
import getProfileByEmail from '../firebase/getProfileByEmail';
import '@fortawesome/fontawesome-free/css/all.min.css';


const db = getFirestore(app);
const provider = new GoogleAuthProvider();

const SignIn = () => {
    const history = useHistory();
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [profile, setProfile] = useState(null);

    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true)
        const auth = getAuth(app);
        signInWithEmailAndPassword(auth, emailAddress, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                fetchProfile(emailAddress)
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setIsLoading(false)
                if (errorCode == 'auth/network-request-failed') {
                    NotificationManager.error('Check network connection')

                } else {
                    NotificationManager.error('Invalid email or password')

                }
            });
    };

    const handleGoogleSignIn = (e) => {
        e.preventDefault();
        // setIsLoading(true)
        const auth = getAuth();
        // signInWithPopup(auth, provider)
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                fetchProfile(user.email)
               // console.log('user:: ', user)
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
                if (errorCode == 'auth/network-request-failed') {
                    NotificationManager.error('Check network connection')
                } else {
                    NotificationManager.error(errorMessage)
                }
            });
    }

    const fetchProfile = async (emailValue) => {
        const profileData = await getProfileByEmail(emailValue);
        setIsLoading(false);
        if(profileData){
            setProfile(profileData);
            history.push('/panel',{profileData});
        }else{
            NotificationManager.error('Record not found')
        }
        
    };

    // if (!profile) {
    //     return <div>No profile found</div>;
    // }

    return (
        <div className="bg-gray-100 md:bg-white rounded-lg py-5">
            <div className="container flex flex-col mx-auto bg-gray-100 md:bg-white rounded-lg pt-12 my-5">
                <div className="flex justify-center w-full h-full my-auto xl:gap-14 lg:justify-normal md:gap-5">
                    <div className="px-5 md:px-0 md:flex md:items-center md:justify-center w-full lg:p-12">
                        <div className="flex items-center xl:p-10">
                            <form  className="flex flex-col w-full h-full p-5 md:p-0 pb-6 text-center bg-white rounded-3xl ">
                                <h3 className="mb-3 text-4xl font-extrabold text-blue-950 text-dark-grey-900">Sign In</h3>
                                <p className="mb-4 text-gray-500">Enter your email and password</p>
                                <button
                                    className="flex items-center justify-center w-full py-4 mb-6 text-sm font-medium transition duration-300 rounded-2xl text-grey-900 bg-blue-50 hover:bg-grey-400 focus:ring-4 focus:ring-grey-300"
                                    onClick={handleGoogleSignIn}
                                >
                                    <img className="h-5 mr-2" src={googleLogo} alt="Google logo" />
                                    Sign in with Google
                                </button>
                                <div className="flex items-center mb-3">
                                    <hr className="h-0 border-b border-solid border-grey-500 grow" />
                                    <p className="mx-4 text-grey-600">or</p>
                                    <hr className="h-0 border-b border-solid border-grey-500 grow" />
                                </div>
                                <label htmlFor="email" className="mb-2 text-sm text-start text-grey-900">
                                    Email*
                                </label>
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="info@beqa.com"
                                    value={emailAddress}
                                    disabled={isLoading}
                                    onChange={(e) => setEmailAddress(e.target.value)}
                                    required
                                    className="flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 mb-7 placeholder:text-grey-700 bg-blue-50 text-dark-grey-900 rounded-2xl"
                                />
                                <label htmlFor="password" className="mb-2 text-sm text-start text-grey-900">
                                    Password*
                                </label>
                                <input
                                    id="password"
                                    type="password"
                                    placeholder="Enter a password"
                                    value={password}
                                    disabled={isLoading}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    className="flex items-center w-full px-5 py-4 mb-5 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-blue-50 text-dark-grey-900 rounded-2xl"
                                />
                                <div className="flex flex-row justify-end mb-8">
                    
                                    <Link to="" className="mr-4 text-sm font-medium text-purple-blue-500">
                                        Forget password?
                                    </Link>
                                </div>
                                <button
                                    onClick={handleSubmit}
                                    className="w-full bg-blue-950 px-6 py-5 mb-5 font-bold leading-none text-white transition duration-300 md:w-96 rounded-2xl hover:bg-purple-blue-600 focus:ring-4 focus:ring-purple-blue-100"
                                >
                                    {isLoading ? <i className="fas fa-circle-notch fa-spin text-base"></i> : 'Sign In'}
                                </button>
           
                            </form>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    );
};

export default SignIn;
