import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaAngleDoubleLeft, FaAngleLeft, FaCheck, FaAngleRight, FaAngleDoubleRight, FaDownload } from 'react-icons/fa';
import SkeletonLoading from './SkeletonLoading';
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import Swal from 'sweetalert2';
import { Tooltip } from 'react-tooltip';

const RemitanceSummary = ({ profileData }) => {
    const [transactions, setTransactions] = useState([]);
    const [pageToken, setPageToken] = useState(null);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [prevPageToken, setPrevPageToken] = useState(null);
    const [pageDirection, setPageDirection] = useState('next')
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalAgencyAmount, setTotalAgencyAmount] = useState(0);
    const [totalAgentAmount, setTotalAgentAmount] = useState(0);
    const [totalGovtAmount, setTotalGovtAmount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [profiles, setProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState("");
    


    useEffect(() => {
        fetchTransactions('next');
        fetchProfiles(profileData.agency_id);

    }, [pageSize]);

    const fetchTransactions = async (directionValue) => {
        setIsLoading(true)
        const response = await axios.post('https://us-central1-igr-service.cloudfunctions.net/app/api/remittances/read', {
            direction: directionValue,
            pageToken: directionValue === 'next' ? nextPageToken : prevPageToken,
            limit: pageSize.toString(),
            filters: [
                { field: 'oid', operator: '==', value: profileData.organisation_id },
            ]

        });
        
        if (response.data.status == 200) {
            setNextPageToken(response.data.result.nextPageToken);
            setTotalPages(response.data.result.pageSize)
            setPrevPageToken(response.data.result.prevPageToken);
            setTransactions(response.data.result.transactions);
            setIsLoading(false)
        }
        else {
            NotificationManager.error('An error occurs while getting record! Kindly refresh the page')
        }

    };
    const filterByProfile = async (filterset) => {
        setIsLoading(true)
        const response = await axios.post('https://us-central1-igr-service.cloudfunctions.net/app/api/remittances/read', {
            direction: 'next',
            limit: pageSize.toString(),
            filters: filterset

        });
        console.log('DATA>>> ',response)
        if (response.data.status == 200) {
            setNextPageToken(response.data.result.nextPageToken);
            setTotalPages(response.data.result.pageSize)
            setPrevPageToken(response.data.result.prevPageToken);
            setTransactions(response.data.result.transactions);
            setIsLoading(false)
        }
        else {
            NotificationManager.error('An error occurs while getting record! Kindly refresh the page')
        }

    };

    const fetchProfiles = async (agencyId) => {
        try {
            const response = await axios.get(
                `https://us-central1-igr-service.cloudfunctions.net/app/api/dropdown/profile/agencyId?value=${agencyId}`
            );
            setProfiles(response.data.message);
        } catch (error) {
            console.error("Error fetching profiles:", error);
        }
    };


    const handleClick = (pageNumber, direction) => {
        setCurrentPage(pageNumber);
        setPageDirection(direction)
        //setPageToken(direction === 'next' ? nextPageToken:prevPageToken)
        fetchTransactions(direction);
    };

    const handlePageSizeChange = (event) => {
        setCurrentPage(Number(event.target.value));
        // setCurrentPage(1);
    };


    const removeTimeFromDate = (dateString) => {
        if (typeof dateString !== 'string') return '';

        const date = new Date(dateString);
        if (isNaN(date)) {
            // If the dateString cannot be parsed, return the original string (or an empty string)
            return dateString.split(' ')[0];
        }

        // Format the date to YYYY-MM-DD
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const handleDownloadCSV = () => {
        const csvData = [
            ['S/N', 'ID', 'Date', 'profile', 'LGA', 'Station', 'Amount', 'Status'],
            ...transactions.map((transaction, index) => [
                index + 1,
                transaction.id,
                removeTimeFromDate(transaction.entryDate),
                transaction.profile,
                transaction.lga,
                transaction.station,
                transaction.amount,
                transaction.status,

            ])
        ];

        const csvContent = "data:text/csv;charset=utf-8,"
            + csvData.map(e => e.join(",")).join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "transactions_report.csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    };

    function ApproveTransactionButton({ remittanceId, index }) {
        const handleApprove = async () => {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "Are you sure you want mark remittance as remitted?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, mark as remitted!',
                cancelButtonText: 'No, cancel!',
            });

            if (result.isConfirmed) {
                try {
                    const response = await axios.post('https://us-central1-igr-service.cloudfunctions.net/app/api/remittances/pay', {
                        remittanceId: remittanceId,
                        sessionId: "RECONCILLIATION",
                        sourceBank: "Zenith Bank"
                    });
                    if (response.data.status === 200) {
                        var updated = transactions[index];
                        updated.status = 'PAID';
                        var trans = [...transactions];
                        trans[index] = updated;
                        setTransactions(trans);
                        Swal.fire({
                            title: 'Approved!',
                            text: 'Remittance has been approved successfully.',
                            icon: 'success'
                        });
                    } else {
                        Swal.fire({
                            title: 'Error!',
                            text: 'There was a problem approving the transaction. Please try again.',
                            icon: 'error'
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        title: 'Error!',
                        text: 'There was a problem approving the transaction. Please try again.',
                        icon: 'error'
                    });
                }
            }
        };

        return (
            <>
                <Tooltip id="approve-tooltip" />
                <button
                    className="text-white bg-blue-500 px-6 py-2.5 rounded-full hover:text-gray-500"
                    onClick={handleApprove}
                    data-tooltip-id="approve-tooltip"
                    data-tooltip-content="Mark as remitted"
                >
                    <FaCheck />
                </button>
            </>
        );
    }

    const handleChange = (e) => {
        setSelectedProfile(e.target.value);
        filterByProfile( [
            { field: 'oid', operator: '==', value: profileData.organisation_id },
            { field: 'profileId', operator: '==', value: e.target.value }
        ])
    };

    if (isLoading) return <SkeletonLoading />

    return (
        <div className="container mx-auto px-4 sm:px-8">
            <div className="py-8">
                
                <div className='grid grid-flow-col justify-between'>
                <div className='mb-4'>
                    <button onClick={handleDownloadCSV} className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">
                        <FaDownload className="mr-2" />
                        Download CSV
                    </button>
                </div>
                <div className="mb-4">
                   
                    <select
                        id="profile"
                        name="profile"
                        value={selectedProfile}
                        onChange={handleChange}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    >
                        <option value="">Select a profile</option>
                        {profiles.map((profile) => (
                            <option key={profile.id} value={profile.id}>
                                {profile.name}
                            </option>
                        ))}
                    </select>
                </div>
                </div>

                <div className="">
                    <div className="overflow-x-auto">
                        <table className="min-w-full leading-normal">
                            <thead>
                                <tr>
                                    {['S/N', 'Date','Id', 'Profile', 'lga', 'Station', 'Amount', 'status', 'Action'].map((heading) => (
                                        <th
                                            key={heading}
                                            className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider"
                                        >
                                            {heading}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className='text-gray-600 text-sm font-light'>
                                {transactions.map((transaction, index) => (
                                    <tr key={transaction.id}>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{(currentPage - 1) * pageSize + index + 1}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap">{removeTimeFromDate(transaction.entryDate)}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.id}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.profile}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.lga}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.station}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.amount}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.status}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap" >
                                            {'...'}

                                        </td>

                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                    <div className="flex justify-between items-center py-4">
                        <div className="flex items-center space-x-2">
                            <button
                                className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                                onClick={() => handleClick(1, 'previous')}
                                disabled={currentPage === 1}
                            >
                                <FaAngleDoubleLeft />
                            </button>
                            <button
                                className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                                onClick={() => handleClick(currentPage - 1, 'previous')}
                                disabled={currentPage === 1}
                            >
                                <FaAngleLeft />
                            </button>
                            <span className="text-gray-600 mx-2">
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                                onClick={() => handleClick(currentPage + 1, 'next')}
                                disabled={currentPage === totalPages}
                            >
                                <FaAngleRight />
                            </button>
                            <button
                                className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                                onClick={() => handleClick(totalPages, 'next')}
                                disabled={currentPage === totalPages}
                            >
                                <FaAngleDoubleRight />
                            </button>
                        </div>

                        <div className="flex items-center">
                            <label htmlFor="pageSize" className="mr-2 text-gray-600">Page Size:</label>
                            <select
                                id="pageSize"
                                value={pageSize}
                                onChange={handlePageSizeChange}
                                className="border border-gray-300 rounded p-1"
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RemitanceSummary;
