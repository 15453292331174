import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaDownload } from 'react-icons/fa';
import SkeletonLoading from './SkeletonLoading';
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'


const TransactionsReport = () => {
    const [transactions, setTransactions] = useState([]);
    const [pageToken, setPageToken] = useState(null);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [prevPageToken, setPrevPageToken] = useState(null);
    const [pageDirection, setPageDirection] = useState('next')
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalAgencyAmount, setTotalAgencyAmount] = useState(0);
    const [totalAgentAmount, setTotalAgentAmount] = useState(0);
    const [totalGovtAmount, setTotalGovtAmount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);


    useEffect(() => {
        fetchTransactions('next');
    }, [pageSize]);

    const fetchTransactions = async (directionValue) => {
        setIsLoading(true)
        const response = await axios.post('https://us-central1-igr-service.cloudfunctions.net/app/api/transactions/read', {
            direction: directionValue,
            pageToken: directionValue === 'next' ? nextPageToken : prevPageToken,
            limit: pageSize.toString(),
            filters: [
                { field: 'oid', operator: '==', value: '07' },
                { field: 'cardResponseCode', operator: '==', value: '00' }
            ]
        });
        if (response.data.status == 200) {
            setTotalPages(response.data.result.pageSize)
            setTransactions(response.data.result.transactions);
            setNextPageToken(response.data.result.nextPageToken);
            setPrevPageToken(response.data.result.prevPageToken);
            setTotalAmount(response.data.result.summary.totalAmount);
            setTotalAgencyAmount(response.data.result.summary.totalAgencyAmount);
            setTotalAgentAmount(response.data.result.summary.totalAgentAmount);
            setTotalGovtAmount(response.data.result.summary.totalGovtAmount);
            setIsLoading(false)
        }
        else {
            NotificationManager.error('An error occurs while getting record! Kindly refresh the page')
        }

    };

    const handleClick = (pageNumber, direction) => {
        setCurrentPage(pageNumber);
        setPageDirection(direction)
        console.log('curr:: ',pageNumber)
        //setPageToken(direction === 'next' ? nextPageToken:prevPageToken)
        fetchTransactions(direction);
    };

    const handlePageSizeChange = (event) => {
        setCurrentPage(Number(event.target.value));
        // setCurrentPage(1);
    };


    const removeTimeFromDate = (dateString) => {
        if (typeof dateString !== 'string') return '';

        const date = new Date(dateString);
        if (isNaN(date)) {
            // If the dateString cannot be parsed, return the original string (or an empty string)
            return dateString.split(' ')[0];
        }

        // Format the date to YYYY-MM-DD
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const handleDownloadCSV = () => {
        const csvData = [
            ['S/N', 'Station', 'TxCode', 'Payer', 'Payer ID', 'Note', 'Operator', 'Remittance ID', 'Date Remitted', 'Txn Date', 'Entry Date', 'Subhead Amount', 'Subhead', 'Txn Amount', 'Agency Amount', 'Agent Amount', 'Govt. Amount', 'Card Status', 'Response Code', 'TID'],
            ...transactions.map((transaction, index) => [
                index + 1,
                transaction.station,
                transaction.txCode,
                transaction.payerName,
                transaction.payerId,
                transaction.note,
                transaction.profileName,
                transaction.remittanceId,
                removeTimeFromDate(transaction.txTime),
                removeTimeFromDate(transaction.entryDate),
                removeTimeFromDate(transaction.entryDate),
                transaction.amount,
                transaction.subhead,
                transaction.amount,
                transaction.agencyAmount,
                transaction.agentAmount,
                transaction.govtAmount,
                transaction.cardStatus,
                transaction.cardResponseCode,
                transaction.tid
            ])
        ];

        const csvContent = "data:text/csv;charset=utf-8,"
            + csvData.map(e => e.join(",")).join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "transactions_report.csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    };

    if (isLoading) return <SkeletonLoading />

    return (
        <div className="container mx-auto px-4 sm:px-8">
            <div className="py-8">
                <div className='mb-4'>
                    <button onClick={handleDownloadCSV} className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">
                        <FaDownload className="mr-2" />
                        Download CSV
                    </button>
                </div>
                <div className="">
                    <div className="overflow-x-auto">
                        <table className="min-w-full leading-normal">
                            <thead>
                                <tr>
                                    {['S/N', 'Station', 'TxCode', 'Payer', 'Payer ID', 'Note', 'Operator', 'Remittance ID', 'Date Remitted', 'Txn Date', 'Entry Date', 'Subhead Amount', 'Subhead', 'Txn Amount', 'Agency Amount', 'Agent Amount', 'Govt. Amount', 'Card Status', 'Response Code', 'TID'].map((heading) => (
                                        <th
                                            key={heading}
                                            className="py-3 px-4 text-left whitespace-nowrap border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider"
                                        >
                                            {heading}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className='text-gray-600 text-sm font-light'>
                                {transactions.map((transaction, index) => (
                                    <tr key={transaction.id}>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{(currentPage - 1) * pageSize + index + 1}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.station}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.txCode}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.payerName}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.payerId}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.note}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.profileName}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.remittanceId}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap">{removeTimeFromDate(transaction.txTime)}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap">{removeTimeFromDate(transaction.entryDate)}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap">{removeTimeFromDate(transaction.entryDate)}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.amount}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.subhead}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.amount}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.agencyAmount}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.agentAmount}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.govtAmount}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.cardStatus}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.cardResponseCode}</td>
                                        <td className="py-3 px-4 text-left whitespace-nowrap border-b border-gray-200 bg-white text-sm">{transaction.tid}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>

                                    <td colSpan="13" className="py-3 px-4 text-right whitespace-nowrap border-t border-gray-200 bg-gray-100 text-sm font-semibold">Total:</td>
                                    <td className="py-3 px-4 text-left whitespace-nowrap border-t border-gray-200 bg-gray-100 text-sm font-semibold">{totalAmount}</td>
                                    <td className="py-3 px-4 text-left whitespace-nowrap border-t border-gray-200 bg-gray-100 text-sm font-semibold">{totalAgencyAmount}</td>
                                    <td className="py-3 px-4 text-left whitespace-nowrap border-t border-gray-200 bg-gray-100 text-sm font-semibold">{totalAgentAmount}</td>
                                    <td className="py-3 px-4 text-left whitespace-nowrap border-t border-gray-200 bg-gray-100 text-sm font-semibold">{totalGovtAmount}</td>
                                    <td colSpan="3" className="py-3 px-4 text-left whitespace-nowrap border-t border-gray-200 bg-gray-100 text-sm font-semibold"></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className="flex justify-between items-center py-4">
                        <div className="flex items-center space-x-2">
                            <button
                                className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                                onClick={() => handleClick(1, 'previous')}
                                disabled={currentPage === 1}
                            >
                                <FaAngleDoubleLeft />
                            </button>
                            <button
                                className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                                onClick={() => handleClick(currentPage - 1, 'previous')}
                                disabled={currentPage === 1}
                            >
                                <FaAngleLeft />
                            </button>
                            <span className="text-gray-600 mx-2">
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                                onClick={() => handleClick(currentPage + 1, 'next')}
                                disabled={currentPage === totalPages}
                            >
                                <FaAngleRight />
                            </button>
                            <button
                                className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                                onClick={() => handleClick(totalPages, 'next')}
                                disabled={currentPage === totalPages}
                            >
                                <FaAngleDoubleRight />
                            </button>
                        </div>

                        <div className="flex items-center">
                            <label htmlFor="pageSize" className="mr-2 text-gray-600">Page Size:</label>
                            <select
                                id="pageSize"
                                value={pageSize}
                                onChange={handlePageSizeChange}
                                className="border border-gray-300 rounded p-1"
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionsReport;
