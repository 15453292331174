import React, { useEffect } from "react";
import { getAuth,signOut  } from "firebase/auth";

import Main from './components/Main';
import './css/styles.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications'
import SignIn from './components/SignIn';
import AdminPanel from './components/AdminPanel';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './components/Dashboard';
import PDFViewer from './components/PDFViewer'
import { SyncLgaProvider } from './components/SyncLgaContext';

const SESSION_TIMEOUT = 30 * 60 * 1000;

const useSessionTimeout = () => {
  useEffect(() => {
    const auth = getAuth();

    // Function to log out the user
    const logout = () => {
      signOut(auth).then(() => {
        // console.log("User signed out due to inactivity.");
      }).catch((error) => {
        // console.error("Error signing out: ", error);
      });
    };

    // Set a timeout to log out the user after the specified duration
    const timeout = setTimeout(logout, SESSION_TIMEOUT);

    // Clear the timeout if the component unmounts or the user performs an action
    const resetTimeout = () => {
      clearTimeout(timeout);
      setTimeout(logout, SESSION_TIMEOUT);
    };

    // Attach event listeners to reset the timeout on user interactions
    window.addEventListener("mousemove", resetTimeout);
    window.addEventListener("keydown", resetTimeout);

    // Clean up event listeners and timeout on component unmount
    return () => {
      clearTimeout(timeout);
      window.removeEventListener("mousemove", resetTimeout);
      window.removeEventListener("keydown", resetTimeout);
    };
  }, []);
};

function App() {
  //useSessionTimeout();
  return (
    <div>
      <SyncLgaProvider>
        <Router>
          {/* <Header /> */}
          <Switch>
            <Route exact path="/" component={Main} />

            {/* <Route path="/academic-calendar" render={() => } />
          <Route path="/ssce-clearance-guide" render={() => <PDFViewer fileUrl="/media/pdf/PrePrimarySchool.pdf" />} />
          <Route path="/pre-primary-school" render={() => <PDFViewer fileUrl="/media/pdf/PrePrimarySchool.pdf" />} /> */}

            <Route path="/media/pdf/:pdfFile" component={PDFViewer} />

            <Route exact path="/signin" component={SignIn} />
            <ProtectedRoute exact path="/panel" component={AdminPanel} />
            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          </Switch>
        </Router>
        <NotificationContainer />
      </SyncLgaProvider>

    </div>
  );
}

export default App;
